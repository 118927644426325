import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const regionSlice = createSlice({
  name: 'regionSlice',
  initialState: {
    isRequonModalRequired: false,
    changedRegion: null,
  },
  reducers: {
    setRegionModalRequired: (state, action) => {
      state.isRequonModalRequired = action.payload;
    },
    setChangedRegion: (state, action) => {
      state.changedRegion = action.payload;
    },
  },
});

export const {
  actions: { setRegionModalRequired, setChangedRegion },
} = regionSlice;

const regionStore = (store: RootState) => store?.regionSlice;

export const selectIsRegionModalRequired = () =>
  createSelector(
    [regionStore],
    (store) => store?.isRequonModalRequired ?? null
  );

export const selectChangedRegion = () =>
  createSelector([regionStore], (store) => store?.changedRegion ?? null);
