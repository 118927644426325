export const Telegram = ({ width = '31', height = '30' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        d="M2.95383 14.8424L8.99174 16.893L23.3264 8.12943C23.5342 8.00235 23.7471 8.28456 23.5679 8.44952L12.7155 18.4387L12.3119 24.0311C12.2812 24.4565 12.7936 24.6933 13.0978 24.3943L16.4392 21.1085L22.5477 25.7327C23.2061 26.2312 24.1595 25.8798 24.3371 25.0733L28.5905 5.76085C28.8331 4.65913 27.7536 3.72946 26.7 4.13293L2.92311 13.2377C2.17724 13.5234 2.19755 14.5856 2.95383 14.8424Z"
        fill="white"
      />
    </svg>
  );
};
