import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import {
  IBigWins,
  IBlogPost,
  ICasino,
  IExtendedCollection,
  IProvider,
  ISlot,
} from 'constants/types';
import { apiQuery } from 'store/query';

export interface Home {
  title?: string;
  allSlots: ISlot[];
  subtitle?: string;
  bigwins: IBigWins[];
  bigwinsCount: number;
  allProviders: IProvider[];
  allCasinos: ICasino[];
  allSlotsCount: number;
  blogPostsCount: number;
  blogPosts: IBlogPost[];
  allCasinosCount: number;
  slotsCollections: IExtendedCollection[];
  casinoCollections: IExtendedCollection[];
}

export const homeApi = createApi({
  tagTypes: [],
  reducerPath: 'homeApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    home: build.query<Home, { region: string | null }>({
      query: ({ region }) => {
        const params = new URLSearchParams();

        if (params) {
          params.append('reg', region || '');
        }

        return {
          method: 'GET',
          url: `${URLS.HOME}?${params.toString()}`,
        };
      },
    }),
  }),
});

export const { useHomeQuery } = homeApi;
