export const ReadPost = ({ width = '21', height = '20', fill = '#121212' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_105_8641)">
        <path
          d="M19.5 6C19.298 6 14.65 6.029 10.5 8.008C6.35 6.029 1.702 6 1.5 6C1.23478 6 0.98043 6.10536 0.792893 6.29289C0.605357 6.48043 0.5 6.73478 0.5 7V16.883C0.500001 17.0172 0.527006 17.15 0.579404 17.2735C0.631803 17.397 0.708525 17.5087 0.805 17.602C1 17.79 1.285 17.907 1.534 17.882L1.661 17.881C2.344 17.881 5.957 17.979 10.077 19.906C10.093 19.914 10.111 19.911 10.127 19.917C10.246 19.966 10.371 20 10.5 20C10.629 20 10.754 19.966 10.874 19.917C10.89 19.911 10.908 19.914 10.924 19.906C15.044 17.978 18.657 17.881 19.34 17.881L19.467 17.882C19.705 17.907 20 17.79 20.196 17.602C20.39 17.413 20.5 17.153 20.5 16.883V7C20.5 6.73478 20.3946 6.48043 20.2071 6.29289C20.0196 6.10536 19.7652 6 19.5 6ZM2.5 8.049C3.985 8.16 6.881 8.529 9.5 9.741V17.483C6.5 16.308 3.91 15.989 2.5 15.907V8.049ZM18.5 15.907C17.09 15.989 14.5 16.308 11.5 17.483V9.741C14.119 8.529 17.015 8.16 18.5 8.049V15.907Z"
          fill={fill}
        />
        <path
          d="M10.5 6C12.1569 6 13.5 4.65685 13.5 3C13.5 1.34315 12.1569 0 10.5 0C8.84315 0 7.5 1.34315 7.5 3C7.5 4.65685 8.84315 6 10.5 6Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_105_8641">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
