export const Inspect = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_373_1705)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H18C19.103 0 20 0.897 20 2V14C20 15.103 19.103 16 18 16H5.333L0 20V2C0 0.897 0.897 0 2 0ZM4.667 14H18V2H2V16L4.667 14ZM11 12H9V9H6V7H9V4H11V7H14V9H11V12Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_373_1705">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
