import axios, { AxiosRequestConfig } from 'axios';

import { AppDispatch } from 'store/store';
import { getToken } from 'utils/storage';

import { BASE_URL } from './constants';

export const apiInstance = axios.create({ baseURL: BASE_URL });

interface Authorization {
  Authorization?: string;
}

const configRequest = (axiosConfig: AxiosRequestConfig) => {
  const token = getToken();
  const config = {
    ...(axiosConfig as AxiosRequestConfig & { headers?: Authorization }),
  };
  if (token) {
    let headers = {
      ...(config.headers as Authorization),
    };
    headers.Authorization = `Token ${token}`;
    config.headers = headers;
  } else {
    delete config.headers?.Authorization;
  }

  return config;
};

const handleResponseError = (dispatch: AppDispatch, error: any) => {
  if (error && error.response && error.response.status === 401) {
  }
  return Promise.reject(error);
};

apiInstance.interceptors.request.use(
  async (config) => configRequest(config) as any
);

export const interceptor = (dispatch: AppDispatch) => {
  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      return handleResponseError(dispatch, error);
    }
  );
};
