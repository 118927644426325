export const Cherry = ({ width = '20', height = '20', fill = '#CAF413' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_188_1447)">
        <path
          id="figure"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7887 0.0543155C17.0811 -0.0824421 17.4301 0.0497451 17.5646 0.345018C17.6985 0.639119 17.5686 0.987009 17.2739 1.12095L14.4277 2.41724L14.1714 2.92974C13.4968 4.27939 13.3202 6.24286 13.5959 8.005C13.1957 7.91637 12.7936 7.86051 12.397 7.86051L12.3938 7.86079C12.1849 6.24204 12.3368 4.54411 12.8428 3.13904L11.0357 3.962C9.59115 4.58126 8.20345 6.14262 7.34744 7.87637C6.95006 7.89621 6.54924 7.94856 6.15006 8.05563C6.11509 8.06511 6.07537 8.06827 6.0355 8.07144C6.02263 8.07247 6.00975 8.07349 5.99701 8.07473C6.14135 7.72141 6.31213 7.37481 6.49439 7.03305H6.48424C3.57624 7.03305 1.21069 4.66747 1.21069 1.75951V0.587596C1.21069 0.263729 1.47276 0.0016593 1.79663 0.0016593H2.96854C5.66631 0.0016593 7.87162 2.04513 8.18009 4.66138C8.90751 3.88275 9.7188 3.25177 10.5624 2.89021L16.7887 0.0543155ZM18.4368 10.099C17.5419 9.13937 16.1043 8.79945 14.6578 9.18745C14.3002 9.28359 13.8979 9.28187 13.5471 9.18804C12.6548 8.9487 11.7813 8.98984 11.0299 9.28124C11.0331 9.28462 11.0367 9.28743 11.0403 9.29025C11.0441 9.29318 11.0478 9.2961 11.0511 9.29964C12.2625 10.598 12.9297 12.3296 12.9297 14.1035C12.9297 15.9631 12.2052 17.7557 10.9194 19.0644C11.5061 19.4447 12.1517 19.7247 12.8336 19.8681C13.253 19.9563 13.6747 19.9997 14.0919 19.9997C17.3418 19.9997 20.0002 17.3438 20.0002 14.1035C20.0001 12.6272 19.4451 11.1799 18.4368 10.099ZM10.1944 10.099C9.29886 9.13942 7.90094 8.79946 6.45438 9.18746C6.09676 9.2836 5.6945 9.28188 5.34368 9.18805C3.88508 8.79782 2.44013 9.14629 1.53942 10.1236C0.263997 11.5078 -0.25444 13.4559 0.11806 15.3322C0.619582 17.8656 2.92692 19.9997 5.88844 19.9997C7.23316 19.9997 8.53492 19.5488 9.58726 18.6951C10.9669 17.5758 11.7577 15.8773 11.7577 14.1035C11.7576 12.6271 11.2026 11.1799 10.1944 10.099Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_188_1447">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
