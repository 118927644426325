export const Save = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2V20L10 15.428L2 20V2C2 0.897 2.897 0 4 0H16C17.103 0 18 0.897 18 2ZM11 9V12H9V9H6V7H9V4H11V7H14V9H11Z"
        fill="white"
      />
    </svg>
  );
};
