import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { getCurrentRegion } from 'utils/storage';

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: {
    region: getCurrentRegion() ?? null,
  },
  reducers: {
    setRegion: (state, action) => {
      state.region = action.payload;
    },
  },
});

export const {
  actions: { setRegion },
} = settingsSlice;

const authStore = (store: RootState) => store?.settingsSlice;

export const selectRegion = () =>
  createSelector([authStore], (store) => store?.region ?? null);
