import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authApi } from './api/auth';
import { variablesApi } from './api/variables';
import { blogPostApi } from './api/blog_post';
import { casinoApi } from './api/casino';
import { casinoReviewsApi } from './api/casino_reviews';
import { countriesApi } from './api/countries';
import { exampleApi } from './api/example';
import { favoriteApi } from './api/favorite';
import { searchApi } from './api/search';
import { slotReviewsApi } from './api/slot_reviews';
import { slotApi } from './api/slots';
import { usersApi } from './api/users';
import { authSlice } from './slices/auth';
import { loaderReducer } from './slices/loader';
import { bigwinsApi } from './api/bigwins';
import { providerApi } from './api/provider';
import { settingsSlice } from './slices/settings';
import { slotCollectionApi } from './api/slot_collection';
import { casinoCollectionApi } from './api/casino_collection';
import { paginationApi, paginationSlice } from './api/pagination';
import { ticketsApi } from './api/tickets';
import { weightsApi } from './api/weights';
import { homeApi } from './api/home';
import { regionSlice } from './slices/regions';

const appReducer = combineReducers({
  loader: loaderReducer,
  [authSlice.name]: authSlice.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [slotApi.reducerPath]: slotApi.reducer,
  [regionSlice.name]: regionSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [casinoApi.reducerPath]: casinoApi.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [weightsApi.reducerPath]: weightsApi.reducer,
  [exampleApi.reducerPath]: exampleApi.reducer,
  [bigwinsApi.reducerPath]: bigwinsApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
  [providerApi.reducerPath]: providerApi.reducer,
  [favoriteApi.reducerPath]: favoriteApi.reducer,
  [blogPostApi.reducerPath]: blogPostApi.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
  [variablesApi.reducerPath]: variablesApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [paginationApi.reducerPath]: paginationApi.reducer,
  [slotReviewsApi.reducerPath]: slotReviewsApi.reducer,
  [casinoReviewsApi.reducerPath]: casinoReviewsApi.reducer,
  [slotCollectionApi.reducerPath]: slotCollectionApi.reducer,
  [casinoCollectionApi.reducerPath]: casinoCollectionApi.reducer,
});

export const resetApiState = () => (dispatch: any) => {
  dispatch(usersApi.util.resetApiState());
};

const rootReducer = (state: any, action: any) => {
  if (action.type === 'logout') {
    return undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    authApi.middleware,
    slotApi.middleware,
    homeApi.middleware,
    usersApi.middleware,
    searchApi.middleware,
    casinoApi.middleware,
    exampleApi.middleware,
    ticketsApi.middleware,
    weightsApi.middleware,
    bigwinsApi.middleware,
    favoriteApi.middleware,
    providerApi.middleware,
    blogPostApi.middleware,
    countriesApi.middleware,
    variablesApi.middleware,
    paginationApi.middleware,
    slotReviewsApi.middleware,
    casinoReviewsApi.middleware,
    slotCollectionApi.middleware,
    casinoCollectionApi.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
