export const ArrowSolid = ({ width = 14, height = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49998 6.99863L9.09998 11.8986V2.09863L3.49998 6.99863Z"
        fill="white"
      />
    </svg>
  );
};
