export const Cloud = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M11 17V13H14L10 8L6 13H9V17H11Z" fill="#121212" />
      <path
        d="M5 17H7V15H5C3.346 15 2 13.654 2 12C2 10.596 3.199 9.244 4.673 8.985L5.254 8.883L5.446 8.325C6.149 6.274 7.895 5 10 5C12.757 5 15 7.243 15 10V11H16C17.103 11 18 11.897 18 13C18 14.103 17.103 15 16 15H13V17H16C18.206 17 20 15.206 20 13C19.9985 12.1036 19.6966 11.2336 19.1427 10.5288C18.5888 9.82408 17.8147 9.32526 16.944 9.112C16.507 5.67 13.56 3 10 3C7.244 3 4.85 4.611 3.757 7.15C1.609 7.792 0 9.82 0 12C0 14.757 2.243 17 5 17Z"
        fill="#121212"
      />
    </svg>
  );
};
