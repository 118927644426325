export const Like = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_382_4467)">
        <path
          d="M1.6 15.2H2.4V4.8H1.6C1.17565 4.8 0.768687 4.96857 0.468629 5.26863C0.168571 5.56869 0 5.97565 0 6.4V13.6C0 14.0243 0.168571 14.4313 0.468629 14.7314C0.768687 15.0314 1.17565 15.2 1.6 15.2ZM14.4 4.8H8.8L9.6976 2.1056C9.77769 1.86513 9.79951 1.60908 9.76126 1.35852C9.72301 1.10797 9.62578 0.870091 9.47758 0.664476C9.32939 0.458861 9.13446 0.291394 8.90887 0.175869C8.68327 0.0603435 8.43346 6.56902e-05 8.18 0H8L4 4.3504V15.2H12.8L15.9296 8.3232L16 8V6.4C16 5.97565 15.8314 5.56869 15.5314 5.26863C15.2313 4.96857 14.8243 4.8 14.4 4.8Z"
          fill="#CAF413"
        />
      </g>
      <defs>
        <clipPath id="clip0_382_4467">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
