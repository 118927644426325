export const Edit = ({ width = '20', height = '20', fill = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.045 5.401C17.423 5.023 17.631 4.521 17.631 3.987C17.631 3.453 17.423 2.951 17.045 2.573L15.459 0.987001C15.081 0.609001 14.579 0.401001 14.045 0.401001C13.511 0.401001 13.009 0.609001 12.632 0.986001L2 11.585V16H6.413L17.045 5.401ZM14.045 2.401L15.632 3.986L14.042 5.57L12.456 3.985L14.045 2.401ZM4 14V12.415L11.04 5.397L12.626 6.983L5.587 14H4ZM2 18H18V20H2V18Z"
        fill={fill}
      />
    </svg>
  );
};
