export const ExclamationMark = ({
  width = '20',
  height = '20',
  fill = '#121212',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_102_4258)">
        <path
          d="M9.953 0C4.465 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.493 0 9.953 0ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.567 2 9.953 2C14.391 2 18 5.589 18 10C18 14.411 14.411 18 10 18Z"
          fill={fill}
        />
        <path d="M9 5H11V12H9V5ZM9 13H11V15H9V13Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_102_4258">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
