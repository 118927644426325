import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ICasinoCollection } from 'constants/types';
import { apiQuery } from 'store/query';
import { PaginationResponse, RequestById } from './types';

export const casinoCollectionApi = createApi({
  tagTypes: [],
  reducerPath: 'casinoCollectionApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    casinoCollections: build.query<PaginationResponse<ICasinoCollection>, void>(
      {
        query: () => ({
          method: 'GET',
          url: URLS.CASINOS_COLLECTIONS,
        }),
      }
    ),
    casinoCollectionById: build.query<ICasinoCollection, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.CASINOS_COLLECTION_BY_ID}${id}`,
      }),
    }),
    casinoCollectionByName: build.query<ICasinoCollection, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.CASINOS_COLLECTION_BY_NAME}${id}`,
      }),
    }),
  }),
});

export const {
  useCasinoCollectionsQuery,
  useCasinoCollectionByIdQuery,
  useCasinoCollectionByNameQuery,
} = casinoCollectionApi;
