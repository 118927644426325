export const Netherlands = ({ width = 21, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_683_4496)">
        <rect
          width="21"
          height="15"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.5H21V15.5H0V10.5Z"
          fill="#1E448D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.5H21V5.5H0V0.5Z"
          fill="#B01923"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_4496">
          <rect
            width="21"
            height="15"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
