import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';
import { UsersTags, usersApi } from './users';

type DeleteFavoriteRequest = {
  favoriteId: string;
};

type AddFavoriteRequest = {
  casinoId?: string;
  slotId?: string;
};

export const favoriteApi = createApi({
  tagTypes: [],
  reducerPath: 'favoriteApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    addToFavorite: build.mutation<void, AddFavoriteRequest>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: ({ casinoId, slotId }) => ({
        method: 'POST',
        url: URLS.FAVORITE,
        data: {
          casinoId,
          slotId,
        },
      }),
    }),

    removeFromFavorite: build.mutation<void, DeleteFavoriteRequest>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: ({ favoriteId }) => {
        return {
          method: 'DELETE',
          url: `${URLS.FAVORITE}`,
          data: {
            favoriteId,
          },
        };
      },
    }),
  }),
});

export const { useAddToFavoriteMutation, useRemoveFromFavoriteMutation } =
  favoriteApi;
