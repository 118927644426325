export const ArrowLeft = ({ width = '30', height = '30' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M16.9395 23.5605L8.37905 15L16.9395 6.43951L19.0605 8.56051L12.621 15L19.0605 21.4395L16.9395 23.5605Z"
        fill="#121212"
      />
    </svg>
  );
};
