import { Icon as IconComponent } from 'common/icon';
import { Types } from './types';

import styles from './styles.module.css';

export const Icon = {
  [Types.success]: <IconComponent.Flag />,
  [Types.error]: <IconComponent.Flag fillColor="red" />,
  [Types.info]: null,
};

export const Colors = {
  [Types.success]: styles.success,
  [Types.error]: styles.error,
  [Types.info]: styles.error,
};

export const Title = {
  [Types.success]: 'Success',
  [Types.error]: 'Error',
  [Types.info]: 'Info',
};
