export const Arrow = ({
  width = 30,
  height = 30,
  fill = 'white',
  fillOpacity = '1',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.70697 15.707L14.414 9.99997L8.70697 4.29297L7.29297 5.70697L11.586 9.99997L7.29297 14.293L8.70697 15.707Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};
