export const Calendar = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M9 10H15V16H9V10Z" fill="white" />
      <path
        d="M17 2H15V0H13V2H7V0H5V2H3C1.897 2 1 2.897 1 4V18C1 19.103 1.897 20 3 20H17C18.103 20 19 19.103 19 18V4C19 2.897 18.103 2 17 2ZM17.001 18H3V6H17L17.001 18Z"
        fill="white"
      />
    </svg>
  );
};
