export const SerachPlus = ({
  width = '21',
  height = '20',
  fill = '#121212',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_69_1318)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 8C0.5 3.589 4.089 0 8.5 0C12.911 0 16.5 3.589 16.5 8C16.4996 9.77544 15.905 11.4997 14.811 12.898L19.207 17.294L17.793 18.708L13.397 14.312C11.9988 15.4054 10.275 15.9996 8.5 16C4.089 16 0.5 12.411 0.5 8ZM2.5 8C2.5 11.309 5.191 14 8.5 14C11.809 14 14.5 11.309 14.5 8C14.5 4.691 11.809 2 8.5 2C5.191 2 2.5 4.691 2.5 8ZM7.5 7V4H9.5V7H12.5V9H9.5V12H7.5V9H4.5V7H7.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_69_1318">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
