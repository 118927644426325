export const AllRegionsFlag = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="20" rx="2" fill="#CAF413" />
      <rect opacity="0.6" width="28" height="10" fill="white" />
      <path
        d="M12.7527 12.4501V11.843C12.7527 11.4712 12.8047 11.143 12.9086 10.8587C13.0125 10.5688 13.1793 10.2954 13.409 10.0384C13.6387 9.77585 13.9422 9.50789 14.3195 9.23445C14.6586 8.99382 14.9293 8.77781 15.1316 8.5864C15.3395 8.395 15.4898 8.20632 15.5828 8.02039C15.6813 7.82898 15.7305 7.61296 15.7305 7.37234C15.7305 7.01687 15.5992 6.74617 15.3367 6.56023C15.0742 6.37429 14.7078 6.28132 14.2375 6.28132C13.7672 6.28132 13.2996 6.35515 12.8348 6.50281C12.3754 6.65046 11.9078 6.8446 11.432 7.08523L10.5379 5.28875C11.0848 4.9825 11.6781 4.7364 12.318 4.55046C12.9578 4.35906 13.6578 4.26336 14.418 4.26336C15.5883 4.26336 16.4934 4.545 17.1332 5.10828C17.7785 5.66609 18.1012 6.37703 18.1012 7.24109C18.1012 7.70046 18.0273 8.09968 17.8797 8.43875C17.7375 8.77234 17.5188 9.08406 17.2234 9.3739C16.9336 9.65828 16.5699 9.95906 16.1324 10.2762C15.8043 10.5169 15.5527 10.7192 15.3777 10.8833C15.2027 11.0473 15.0824 11.2114 15.0168 11.3755C14.9566 11.5341 14.9266 11.7282 14.9266 11.9579V12.4501H12.7527ZM12.4902 15.2555C12.4902 14.7415 12.6297 14.3805 12.9086 14.1727C13.193 13.9649 13.5348 13.861 13.934 13.861C14.3223 13.861 14.6559 13.9649 14.9348 14.1727C15.2191 14.3805 15.3613 14.7415 15.3613 15.2555C15.3613 15.7477 15.2191 16.1032 14.9348 16.3219C14.6559 16.5407 14.3223 16.6501 13.934 16.6501C13.5348 16.6501 13.193 16.5407 12.9086 16.3219C12.6297 16.1032 12.4902 15.7477 12.4902 15.2555Z"
        fill="#121212"
      />
    </svg>
  );
};
