export const About = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2 17H18V19H2V17ZM2 13H13V15H2V13ZM2 9H18V11H2V9ZM2 1H18V3H2V1ZM2 5H13V7H2V5Z"
        fill="white"
      />
    </svg>
  );
};
