export const Close = ({ width = 30, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 15C30 23.271 23.271 30 15 30C6.729 30 0 23.271 0 15C0 6.729 6.729 0 15 0C23.271 0 30 6.729 30 15ZM27 15C27 8.3835 21.6165 3 15 3C8.3835 3 3 8.3835 3 15C3 21.6165 8.3835 27 15 27C21.6165 27 27 21.6165 27 15ZM15.0001 17.121L10.7581 21.363L8.63708 19.242L12.8791 15L8.63708 10.758L10.7581 8.63699L15.0001 12.879L19.2421 8.63699L21.3631 10.758L17.1211 15L21.3631 19.242L19.2421 21.363L15.0001 17.121Z"
        fill="white"
      />
    </svg>
  );
};
