export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const SECRET_KEY = process.env.REACT_APP_CRYPTO_KEY || 'secret';

export const URLS = {
  HOME: 'home',

  ME: 'users/me',
  USERS: 'users/user/',
  UPLOAD_PHOTO: '/users/uploadAvatar',
  UPDATE_USERNAME: '/users/updateUsername',
  CHANGE_EMAIL: '/users/changeEmail',
  GOOGLE_AUTH: '/auth/google',
  GOOGLE_LINK: '/auth/google/link',
  GOOGLE_UNLINK: '/auth/google/unlink',
  FACEBOOK_AUTH: '/auth/facebook',
  FACEBOOK_LINK: '/auth/facebook/link',
  FACEBOOK_UNLINK: '/auth/facebook/unlink',
  CHANGE_EMAIL_SUBMIT: '/users/changeEmailSubmit',
  CREATE_USER: 'users/',
  LOGIN: 'login/',
  MAGIC_LINK: 'magic-link/',

  SEARCH: 'search/',

  FAVORITE: 'favorite',

  CASINO_REVIEWS: 'casino-reviews/',
  CASINO_REVIEWS_BY_CASINO: 'casino-reviews/all/',
  CASINO_REVIEWS_GRAPG_BY_CASINO: 'casino-reviews/graph/',
  CASINO_REVIEWS_UPDATE: 'casino-reviews/update',

  CASINO_REVIEWS_LIKES: 'casinoReviewsLikes/',
  CASINO_REVIEWS_DISLIKES: 'casinoReviewsDislikes/',

  SLOT_REVIEWS_BY_CASINO: 'slot-reviews/by-casino',
  SLOT_REVIEWS_ALL_BY_CASINO: 'slot-reviews/all-by-casino',
  SLOT_REVIEWS_ALL_BY_SLOT: 'slot-reviews/all-by-slot',
  SLOT_REVIEWS: 'slot-reviews/',

  CASINOS: 'casino/',
  CASINOS_BY_SLOT: 'casino/by-slot/',
  UPDATE_CASINOS: 'casino/update/',

  SLOTS: 'slots/',
  SLOTS_BY_CASINO: 'slots/by-casino',
  SLOTS_BY_PROVIDER: 'slots/by-provider',
  SLOTS_UPDATE: 'slots/update',

  PROVIDERS: 'provider/',
  PROVIDER_BY_ID: 'provider/',

  COUNTRIES: 'countries/',
  COUNTRIES_UPDATE: 'countries/update/',

  BLOG_POSTS: 'blogPosts/',
  BLOG_UPDATE_POST: 'blogPost/update',

  BIGWINS: '/bigwins',
  BIGWINS_BY_ID: '/bigwins/',
  BIGWINS_UPDATE: '/bigwins/update/',
  BIGWINS_BY_CASINO: '/bigwins/by-casino/',
  BIGWINS_BY_SLOT: '/bigwins/by-slot/',

  VARIABLES: '/variables/',
  VARIABLES_BY_ID: '/variables/by-id/',
  VARIABLES_BY_NAME: '/variables/by-name/',
  VARIABLES_UPDATE: '/variables/update/',

  WEIGHTS_BY_NAME: '/weights/by-name/',

  TICKETS: '/tickets/',

  CASINOS_COLLECTIONS: 'casinoCollections/',
  CASINOS_COLLECTION_BY_ID: 'casinoCollections/by-id/',
  CASINOS_COLLECTION_BY_NAME: 'casinoCollections/by-name/',
  UPDATE_CASINOS_COLLECTION: 'casinoCollections/update/',

  SLOT_COLLECTIONS: 'slotCollections/',
  SLOT_COLLECTION_BY_ID: 'slotCollections/by-id/',
  SLOT_COLLECTION_BY_NAME: 'slotCollections/by-name/',
  UPDATE_SLOT_COLLECTION: 'slotCollections/update/',
};
