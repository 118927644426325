export const LogoPost = ({ width = '30', height = '31' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="none"
    >
      <g opacity="0.5" clipPath="url(#clip0_108_4868)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.183 0.581336C25.6216 0.3762 26.1452 0.57448 26.3469 1.01739C26.5477 1.45854 26.3529 1.98038 25.9109 2.18129L21.6415 4.12572L21.2572 4.89447C20.2452 6.91894 19.9803 9.86415 20.3939 12.5074C19.7935 12.3744 19.1904 12.2906 18.5955 12.2906L18.5908 12.291C18.2774 9.86292 18.5053 7.31603 19.2642 5.20842L16.5535 6.44287C14.3867 7.37175 12.3052 9.7138 11.0212 12.3144C10.4251 12.3442 9.82386 12.4227 9.22509 12.5833C9.17264 12.5975 9.11305 12.6023 9.05325 12.607C9.03395 12.6086 9.01463 12.6101 8.99552 12.612C9.21202 12.082 9.46819 11.5621 9.74159 11.0494H9.72636C5.36435 11.0494 1.81604 7.50107 1.81604 3.13912V1.38126C1.81604 0.895456 2.20914 0.502352 2.69494 0.502352H4.45281C8.49946 0.502352 11.8074 3.56756 12.2701 7.49193C13.3613 6.32398 14.5782 5.37752 15.8436 4.83518L25.183 0.581336ZM27.6553 15.6484C26.3129 14.2089 24.1565 13.699 21.9867 14.281C21.4503 14.4252 20.8469 14.4227 20.3206 14.2819C18.9822 13.9229 17.672 13.9846 16.5448 14.4217C16.5496 14.4268 16.555 14.431 16.5605 14.4352C16.5661 14.4396 16.5717 14.444 16.5767 14.4493C18.3938 16.3969 19.3946 18.9943 19.3946 21.6552C19.3946 24.4445 18.3078 27.1334 16.3792 29.0964C17.2591 29.667 18.2275 30.0869 19.2504 30.3021C19.8795 30.4343 20.5121 30.4995 21.1378 30.4995C26.0127 30.4995 30.0002 26.5155 30.0002 21.6551C30.0002 19.4407 29.1676 17.2697 27.6553 15.6484ZM15.2916 15.6483C13.9483 14.209 11.8514 13.699 9.68156 14.2811C9.14514 14.4253 8.54174 14.4227 8.01551 14.2819C5.82763 13.6966 3.66019 14.2193 2.30914 15.6853C0.395995 17.7615 -0.381659 20.6837 0.177089 23.4981C0.929373 27.2982 4.39038 30.4994 8.83266 30.4994C10.8497 30.4994 12.8024 29.8231 14.3809 28.5425C16.4503 26.8636 17.6365 24.3159 17.6365 21.6551C17.6365 19.4406 16.8039 17.2697 15.2916 15.6483Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_4868">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
