import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';
import { IVariables } from 'constants/types';
import { RequestById, RequestByName } from './types';

export const variablesApi = createApi({
  tagTypes: [],
  reducerPath: 'variablesApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    variables: build.query<IVariables, void>({
      query: () => ({
        method: 'GET',
        url: URLS.VARIABLES,
      }),
    }),
    variablesById: build.query<IVariables, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.VARIABLES_BY_ID}${id}`,
      }),
    }),

    variablesByName: build.query<IVariables, RequestByName>({
      query: ({ name }) => ({
        method: 'GET',
        url: `${URLS.VARIABLES_BY_NAME}${name}`,
      }),
    }),
  }),
});

export const {
  useVariablesQuery,
  useVariablesByIdQuery,
  useVariablesByNameQuery,
} = variablesApi;
