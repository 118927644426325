export const Logout = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_580_3238)">
        <path d="M0 10L5 14V11H14V9H5V6L0 10Z" fill="white" />
        <path
          d="M11.0007 0.998997C9.81825 0.995718 8.64687 1.22711 7.55447 1.67976C6.46206 2.13241 5.47032 2.79733 4.63672 3.636L6.05072 5.05C7.37272 3.728 9.13072 2.999 11.0007 2.999C12.8707 2.999 14.6287 3.728 15.9507 5.05C17.2727 6.372 18.0017 8.13 18.0017 10C18.0017 11.87 17.2727 13.628 15.9507 14.95C14.6287 16.272 12.8707 17.001 11.0007 17.001C9.13072 17.001 7.37272 16.272 6.05072 14.95L4.63672 16.364C6.33572 18.064 8.59572 19.001 11.0007 19.001C13.4057 19.001 15.6657 18.064 17.3647 16.364C19.0647 14.665 20.0017 12.405 20.0017 10C20.0017 7.595 19.0647 5.335 17.3647 3.636C16.5311 2.79733 15.5394 2.13241 14.447 1.67976C13.3546 1.22711 12.1832 0.995718 11.0007 0.998997Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_580_3238">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
