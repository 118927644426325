export const CasinosReview = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_68_1482)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H18C19.103 0 20 0.897 20 2V14C20 15.103 19.103 16 18 16H5.333L0 20V2C0 0.897 0.897 0 2 0ZM4.667 14H18V2H2V16L4.667 14ZM15 5H5V7H15V5ZM12 9H5V11H12V9Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_1482">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
