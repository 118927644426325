import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { IUser } from 'constants/types';
import { apiQuery } from 'store/query';

interface UploadPhotoRequest {
  image: File;
}

interface UpdateUsernameRequest {
  username: string;
}

interface ChangeEmailRequest {
  newEmail: string;
}

interface ChangeEmailSubmitRequest {
  token: string;
}

export const UsersTags = {
  ME: 'ME',
};

export const usersApi = createApi({
  tagTypes: [UsersTags.ME],
  reducerPath: 'profileApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    me: build.query<{ success: boolean; user: IUser }, void | null>({
      query: () => ({
        url: URLS.ME,
        method: 'GET',
      }),
      providesTags: [UsersTags.ME],
    }),
    users: build.query<any, void>({
      query: () => ({
        url: URLS.USERS,
        method: 'GET',
      }),
    }),
    userById: build.query<{ success: boolean; user: IUser }, { id: string }>({
      query: ({ id }) => {
        return {
          method: 'GET',
          url: `${URLS.USERS}${id.toString()}`,
        };
      },
    }),
    userUploadPhoto: build.mutation<void, UploadPhotoRequest>({
      query: ({ image }) => {
        const formData = new FormData();
        formData.append('image', image);

        return {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

          method: 'POST',
          url: URLS.UPLOAD_PHOTO,
          data: formData,
        };
      },
      invalidatesTags: [UsersTags.ME],
    }),
    userUpdateUsername: build.mutation<void, UpdateUsernameRequest>({
      query: ({ username }: UpdateUsernameRequest) => ({
        url: URLS.UPDATE_USERNAME,
        method: 'POST',
        data: {
          username,
        },
      }),
      invalidatesTags: [UsersTags.ME],
    }),
    changeEmail: build.mutation<void, ChangeEmailRequest>({
      query: ({ newEmail }) => ({
        url: URLS.CHANGE_EMAIL,
        method: 'POST',
        data: {
          newEmail,
        },
      }),
    }),
    changeEmailSubmit: build.mutation<void, ChangeEmailSubmitRequest>({
      query: ({ token }) => ({
        url: URLS.CHANGE_EMAIL_SUBMIT,
        method: 'POST',
        data: {
          token,
        },
      }),
      invalidatesTags: [UsersTags.ME],
    }),
  }),
});

export const {
  useMeQuery,
  useUsersQuery,
  useLazyMeQuery,
  useUserByIdQuery,
  useChangeEmailMutation,
  useUserUploadPhotoMutation,
  useChangeEmailSubmitMutation,
  useUserUpdateUsernameMutation,
} = usersApi;
