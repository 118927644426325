import { createAsyncThunk } from '@reduxjs/toolkit';
import { clearAuth } from 'store/slices/auth';
import { clearAll } from 'utils/storage';
import { types } from './types';
import { resetApiState } from 'store/store';

export interface AuthLogout {
  error?: Error;
}

export const logout = createAsyncThunk(
  types.logout,
  async (values: AuthLogout, { dispatch }) => {
    try {
      if (values.error) {
      }
    } catch (error) {
    } finally {
      clearAll();
      dispatch(resetApiState());
      dispatch(clearAuth());
      return {};
    }
  }
);
