import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from 'react-facebook';

export const SocialProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <GoogleOAuthProvider clientId="988231175818-4an9hkb7869rekhdm4s2l9rs2rcg87jt.apps.googleusercontent.com">
      <FacebookProvider appId="662625029294683">{children}</FacebookProvider>
    </GoogleOAuthProvider>
  );
};
