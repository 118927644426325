export const Play = ({
  width = '21',
  height = '20',
  fill = '#BDB4A1',
  backgroundFill = '#121212',
}: {
  width?: string;
  height?: string;
  fill?: string;
  backgroundFill?: string | null;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      {backgroundFill && (
        <path
          opacity="0.6"
          d="M20.495 9.67392C20.3272 4.42008 16.0803 0.173333 10.8266 0.00555205C7.9679 -0.0851477 5.29338 1.00297 3.3168 3.04371C1.41659 5.00555 0.418194 7.5919 0.505524 10.3267C0.673165 15.5804 4.92005 19.8272 10.1738 19.9949C10.2831 19.9984 10.3915 20.0001 10.5002 20.0001C13.2316 20.0001 15.7827 18.9193 17.6836 16.9567C19.5838 14.995 20.5823 12.4085 20.495 9.67392Z"
          fill={backgroundFill}
        />
      )}
      <path
        d="M20.495 9.67392C20.3272 4.42007 16.0803 0.173333 10.8266 0.00555205C7.9679 -0.0851477 5.29338 1.00297 3.3168 3.04371C1.41659 5.00555 0.418194 7.5919 0.505524 10.3267C0.673165 15.5804 4.92005 19.8272 10.1738 19.9949C10.2831 19.9984 10.3915 20.0001 10.5002 20.0001C13.2316 20.0001 15.7827 18.9193 17.6836 16.9567C19.5838 14.995 20.5823 12.4085 20.495 9.67392ZM16.1709 15.4916C14.6703 17.041 12.6563 17.8942 10.5002 17.8942C10.414 17.8942 10.3278 17.8928 10.2409 17.8901C6.09451 17.7577 2.74283 14.406 2.61029 10.2595C2.54136 8.09987 3.32943 6.05758 4.82949 4.50895C6.33011 2.95961 8.34403 2.10639 10.5002 2.10639C10.5864 2.10639 10.6726 2.10779 10.7595 2.11046C14.9059 2.24286 18.2575 5.59454 18.3901 9.74103C18.459 11.9007 17.6708 13.943 16.1709 15.4916Z"
        fill={fill}
      />
      <path
        d="M13.7799 9.41899L9.34549 6.23945C8.87247 5.90023 8.21399 6.23832 8.21399 6.82043V13.1797C8.21399 13.7618 8.87247 14.0999 9.34549 13.7606L13.7798 10.581C14.1777 10.2958 14.1777 9.70429 13.7799 9.41899Z"
        fill={fill}
      />
    </svg>
  );
};
