export const Facebook = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.9333 10.5333V15.9333H8.8V10.5333H7V8.39998H8.8V6.86665C8.8 5.06665 9.93333 4.06665 11.5333 4.06665C12.0151 4.06665 12.4681 4.09542 12.7789 4.11516C12.9412 4.12547 13.0647 4.13332 13.1333 4.13332V6.06665H12C11.1333 6.06665 10.9333 6.46665 10.9333 7.06665V8.39998H13L12.7333 10.5333H10.9333Z"
        fill="#3577E5"
      />
    </svg>
  );
};
