export const SecondPlay = ({ width = '40', height = '40' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        opacity="0.6"
        d="M39.9899 19.3479C39.6543 8.84017 31.1605 0.34668 20.6531 0.0111194C14.9357 -0.17028 9.58663 2.00595 5.63347 6.08744C1.83307 10.0111 -0.163733 15.1838 0.0109268 20.6533C0.346207 31.1607 8.83998 39.6545 19.3474 39.9898C19.5661 39.9968 19.7829 40.0002 20.0003 40.0002C25.463 40.0002 30.5652 37.8385 34.367 33.9135C38.1674 29.9901 40.1645 24.8171 39.9899 19.3479Z"
        fill="#121212"
      />
      <path
        d="M39.9899 19.3479C39.6543 8.84017 31.1605 0.34668 20.6531 0.0111194C14.9357 -0.17028 9.58663 2.00595 5.63347 6.08744C1.83307 10.0111 -0.163733 15.1838 0.0109268 20.6533C0.346207 31.1607 8.83998 39.6545 19.3474 39.9898C19.5661 39.9968 19.7829 40.0002 20.0003 40.0002C25.463 40.0002 30.5652 37.8385 34.367 33.9135C38.1674 29.9901 40.1645 24.8171 39.9899 19.3479ZM31.3417 30.9833C28.3404 34.0819 24.3126 35.7884 20.0003 35.7884C19.8278 35.7884 19.6554 35.7856 19.4816 35.7802C11.1889 35.5154 4.48554 28.8121 4.22046 20.5191C4.08259 16.1998 5.65874 12.1152 8.65886 9.01791C11.6601 5.91924 15.6879 4.21279 20.0003 4.21279C20.1727 4.21279 20.3451 4.2156 20.5189 4.22094C28.8116 4.48573 35.515 11.1891 35.7801 19.4821C35.9179 23.8014 34.3415 27.886 31.3417 30.9833Z"
        fill="white"
      />
      <path
        d="M26.5598 18.838L17.6909 12.4789C16.7449 11.8005 15.4279 12.4767 15.4279 13.6409V26.3594C15.4279 27.5236 16.7449 28.1998 17.6909 27.5213L26.5596 21.162C27.3554 20.5917 27.3554 19.4086 26.5598 18.838Z"
        fill="white"
      />
    </svg>
  );
};
