export const Twitter = ({ width = '31', height = '30' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
    >
      <g clipPath="url(#clip0_240_3958)">
        <path
          d="M30.5 5.69813C29.3844 6.1875 28.1956 6.51187 26.9562 6.66937C28.2312 5.90812 29.2044 4.71187 29.6619 3.27C28.4731 3.97875 27.1606 4.47938 25.7619 4.75875C24.6331 3.55688 23.0244 2.8125 21.2694 2.8125C17.8644 2.8125 15.1231 5.57625 15.1231 8.96438C15.1231 9.45188 15.1644 9.92062 15.2656 10.3669C10.1525 10.1175 5.62813 7.66688 2.58875 3.93375C2.05812 4.85438 1.74688 5.90812 1.74688 7.0425C1.74688 9.1725 2.84375 11.0606 4.47875 12.1537C3.49062 12.135 2.52125 11.8481 1.7 11.3962C1.7 11.415 1.7 11.4394 1.7 11.4637C1.7 14.4525 3.83187 16.935 6.6275 17.5069C6.12687 17.6438 5.58125 17.7094 5.015 17.7094C4.62125 17.7094 4.22375 17.6869 3.85062 17.6044C4.6475 20.04 6.90875 21.8306 9.5975 21.8888C7.505 23.5256 4.84812 24.5119 1.97188 24.5119C1.4675 24.5119 0.98375 24.4894 0.5 24.4275C3.22438 26.1844 6.45312 27.1875 9.935 27.1875C21.2525 27.1875 27.44 17.8125 27.44 9.68625C27.44 9.41437 27.4306 9.15188 27.4175 8.89125C28.6381 8.025 29.6637 6.94313 30.5 5.69813Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_3958">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
