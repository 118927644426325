import { createSelector, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'store/api/auth';
import { usersApi } from 'store/api/users';
import { logout } from 'store/thunks/auth/auth';
import { clearAll, getToken, saveToken } from 'utils/storage';

const getInitialValue = () => {
  const isLoggedIn = getToken() !== undefined;

  return {
    isLoggedIn,
  };
};

const reset = (state: any) => {
  return {
    ...state,
    isLoggedIn: false,
  };
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: getInitialValue(),
  reducers: {
    clearAuth: (state) => {
      return reset(state);
    },
    setLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      return reset(state);
    });
    builder.addCase(logout.rejected, (state) => {
      return reset(state);
    });

    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }: { payload: any }) => {
        const { authToken: accessToken } = payload;
        const isLoggedIn = accessToken !== undefined;

        if (isLoggedIn) {
          saveToken(accessToken);
        } else {
          clearAll();
        }
        return {
          ...state,
          isLoggedIn,
        };
      }
    );

    builder.addMatcher(
      usersApi.endpoints.me.matchFulfilled,
      (state, { payload }: { payload: any }) => {
        return {
          ...state,
          isLoggedIn: payload?.success,
        };
      }
    );
  },
});

export const {
  actions: { clearAuth, setLoggedIn },
} = authSlice;

const authStore = (store: any) => store.authSlice;

export const selectIsLoggedIn = () =>
  createSelector([authStore], (store) => store?.isLoggedIn ?? false);
