import { useEffect, createContext, useContext, useState } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

// Custom hook to access the theme context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// Provider component for the theme context
export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(
    (typeof window !== 'undefined' && localStorage.getItem('theme')) || 'dark'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!localStorage.getItem('theme')) {
        localStorage.setItem('theme', 'dark');
      }
      setCurrentTheme(localStorage.getItem('theme') || 'dark');
    }
  }, []);

  const setTheme = (newTheme) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', newTheme);
      setCurrentTheme(newTheme);
    }
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', currentTheme);
  }, [currentTheme]);

  const contextValue = {
    theme: currentTheme,
    setTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

// ThemeWrapper component
export const ThemeWrapper = ({ children }) => {
  return typeof children === 'function' ? children(useTheme()) : null;
};
