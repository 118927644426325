import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { IProvider } from 'constants/types';
import { apiQuery } from 'store/query';
import { PaginationResponse, RequestById } from './types';

export const providerApi = createApi({
  tagTypes: [],
  reducerPath: 'providerApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    providers: build.query<PaginationResponse<IProvider>, void>({
      query: () => ({
        url: URLS.PROVIDERS,
        method: 'GET',
      }),
    }),
    providerById: build.query<
      IProvider & PaginationResponse<IProvider>,
      RequestById
    >({
      query: ({ id }) => ({
        url: `${URLS.PROVIDER_BY_ID}${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useProviderByIdQuery, useProvidersQuery } = providerApi;
