export const AddComment = ({
  width = '21',
  height = '20',
  fill = '#CAF413',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_103_4291)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 0H18.5C19.603 0 20.5 0.897 20.5 2V14C20.5 15.103 19.603 16 18.5 16H5.833L0.5 20V2C0.5 0.897 1.397 0 2.5 0ZM5.167 14H18.5V2H2.5V16L5.167 14ZM11.5 12H9.5V9H6.5V7H9.5V4H11.5V7H14.5V9H11.5V12Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_103_4291">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
