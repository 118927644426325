import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ISlot } from 'constants/types';
import { apiQuery } from 'store/query';
import { PaginationRequest, PaginationResponse, RequestById } from './types';

type SlotParams = {
  image: string;
  name: string;
  description: string;
};

export type SlotSorting = 'bigwins-asc' | 'bigwins-des' | null | undefined;

interface SlotsRequest extends PaginationRequest {
  ids?: string[];
  sort?: SlotSorting;
  collections?: string;
}

export const slotApi = createApi({
  tagTypes: [],
  reducerPath: 'slotApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    slots: build.query<PaginationResponse<ISlot>, SlotsRequest>({
      query: ({ ids, page, sort, limit, collections }) => {
        const params = new URLSearchParams();

        if (page) {
          params.set('page', page.toString());
        }

        if (limit) {
          params.set('limit', limit.toString());
        }

        if (sort) {
          params.set('sort', sort.toString());
        }

        if (collections) {
          params.set('collections', collections);
        }

        return {
          method: 'GET',
          url: ids
            ? `${URLS.SLOTS}${ids}`
            : `${URLS.SLOTS}?${params.toString()}`,
        };
      },
    }),
    slotsByCasinoId: build.query<PaginationResponse<ISlot>, RequestById>({
      query: ({ id }) => ({
        url: `${URLS.SLOTS_BY_CASINO}/${id}`,
        method: 'GET',
      }),
    }),
    slotsByProviderId: build.query<PaginationResponse<ISlot>, RequestById>({
      query: ({ id }) => ({
        url: `${URLS.SLOTS_BY_PROVIDER}/${id}`,
        method: 'GET',
      }),
    }),
    slotById: build.query<ISlot, RequestById>({
      query: ({ id }) => ({
        url: `${URLS.SLOTS}/${id}`,
        method: 'GET',
      }),
    }),
    createSlot: build.mutation<void, any>({
      query: ({ image, name, description }: SlotParams) => ({
        method: 'POST',
        url: URLS.SLOTS,
        data: {
          image,
          name,
          description,
        },
      }),
    }),
    updateSlot: build.mutation<void, any>({
      query: ({ image, name, description }: SlotParams) => ({
        method: 'POST',
        url: URLS.SLOTS_UPDATE,
        data: {
          image,
          name,
          description,
        },
      }),
    }),
    deleteSlot: build.mutation<void, any>({
      query: ({ id }) => {
        const params = new URLSearchParams({
          slot_id: id,
        });
        return {
          method: 'DELETE',
          url: `${URLS.SLOTS} + ${params.toString()}`,
        };
      },
    }),
  }),
});

export const {
  useSlotsQuery,
  useSlotByIdQuery,
  useCreateSlotMutation,
  useSlotsByCasinoIdQuery,
  useSlotsByProviderIdQuery,
} = slotApi;
