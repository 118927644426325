export const Guide = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1386_3916)">
        <path
          d="M19 6C18.798 6 14.15 6.029 10 8.008C5.85 6.029 1.202 6 1 6C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7V16.883C1.30632e-06 17.0172 0.0270056 17.15 0.0794044 17.2735C0.131803 17.397 0.208525 17.5087 0.305 17.602C0.5 17.79 0.785 17.907 1.034 17.882L1.161 17.881C1.844 17.881 5.457 17.979 9.577 19.906C9.593 19.914 9.611 19.911 9.627 19.917C9.746 19.966 9.871 20 10 20C10.129 20 10.254 19.966 10.374 19.917C10.39 19.911 10.408 19.914 10.424 19.906C14.544 17.978 18.157 17.881 18.84 17.881L18.967 17.882C19.205 17.907 19.5 17.79 19.696 17.602C19.89 17.413 20 17.153 20 16.883V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6ZM2 8.049C3.485 8.16 6.381 8.529 9 9.741V17.483C6 16.308 3.41 15.989 2 15.907V8.049ZM18 15.907C16.59 15.989 14 16.308 11 17.483V9.741C13.619 8.529 16.515 8.16 18 8.049V15.907Z"
          fill="white"
        />
        <path
          d="M10 6C11.6569 6 13 4.65685 13 3C13 1.34315 11.6569 0 10 0C8.34315 0 7 1.34315 7 3C7 4.65685 8.34315 6 10 6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1386_3916">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
