export const Author = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 0C9.01109 0 8.04439 0.293245 7.22215 0.842652C6.3999 1.39206 5.75904 2.17295 5.3806 3.08658C5.00216 4.00021 4.90315 5.00555 5.09607 5.97545C5.289 6.94536 5.7652 7.83627 6.46447 8.53553C7.16373 9.2348 8.05464 9.711 9.02455 9.90393C9.99445 10.0969 10.9998 9.99784 11.9134 9.6194C12.827 9.24096 13.6079 8.6001 14.1573 7.77785C14.7068 6.95561 15 5.98891 15 5C15 3.67392 14.4732 2.40215 13.5355 1.46447C12.5979 0.526784 11.3261 0 10 0ZM10 8C9.40666 8 8.82664 7.82405 8.33329 7.49441C7.83994 7.16476 7.45542 6.69623 7.22836 6.14805C7.0013 5.59987 6.94189 4.99667 7.05764 4.41473C7.1734 3.83279 7.45912 3.29824 7.87868 2.87868C8.29824 2.45912 8.83279 2.1734 9.41473 2.05764C9.99667 1.94189 10.5999 2.0013 11.1481 2.22836C11.6962 2.45542 12.1648 2.83994 12.4944 3.33329C12.8241 3.82664 13 4.40666 13 5C13 5.79565 12.6839 6.55871 12.1213 7.12132C11.5587 7.68393 10.7956 8 10 8ZM19 19V18C19 16.1435 18.2625 14.363 16.9497 13.0503C15.637 11.7375 13.8565 11 12 11H8C6.14348 11 4.36301 11.7375 3.05025 13.0503C1.7375 14.363 1 16.1435 1 18V19H3V18C3 16.6739 3.52678 15.4021 4.46447 14.4645C5.40215 13.5268 6.67392 13 8 13H12C13.3261 13 14.5979 13.5268 15.5355 14.4645C16.4732 15.4021 17 16.6739 17 18V19H19Z"
        fill="white"
      />
    </svg>
  );
};
