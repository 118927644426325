import 'styles/global.css';
//import '../i18n';

import { AppProps } from 'next/app';
import { Provider } from 'react-redux';

import { interceptor } from 'api/instance';
import { ThemeProvider, ThemeWrapper } from 'context/provider.js';
import { store } from 'store';
import { SocialProviders } from 'components/social-providers';
import { AlertProvider } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'store/slices/auth';
import { getRedirectOptions, saveRedirectOptions } from 'utils/redirectOptions';
import { useRouter } from 'next/router';

interceptor(store.dispatch);

const Redirector = () => {
  const router = useRouter();
  const isLoggedIn = useSelector(selectIsLoggedIn());

  useEffect(() => {
    if (isLoggedIn) {
      const redirectOptions = getRedirectOptions();
      if (redirectOptions) {
        const { url, action } = redirectOptions;
        if (url && url !== 'null') {
          router.push(url);
          saveRedirectOptions({ url: 'null', action: action });
        }
      }
    }
  }, [isLoggedIn]);

  return null;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <SocialProviders>
      <Provider store={store}>
        <ThemeProvider>
          <ThemeWrapper>
            {() => (
              <>
                <AlertProvider>
                  <Redirector />
                  <Component {...pageProps} />
                </AlertProvider>
              </>
            )}
          </ThemeWrapper>
        </ThemeProvider>
      </Provider>
    </SocialProviders>
  );
};

export default MyApp;
