import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import {
  ICasino,
  ICasinoReview,
  ISlotReview,
  ISlot,
  IBigWins,
} from 'constants/types';
import { apiQuery } from 'store/query';
import { RootState } from 'store/store';
import { PaginationRequest, PaginationResponse, RequestById } from './types';

interface ListRequest extends PaginationRequest, RequestById {}
interface CasinoListRequest extends PaginationRequest {
  countries?: string | null;
  collections?: string;
}

interface SlotListRequest extends PaginationRequest {
  countries?: string | null;
  collections?: string;
}

interface BigwinsRequest extends PaginationRequest {
  slot: string | null;
}

export interface SlotReviewsRequest extends PaginationRequest {
  page?: number;
  limit?: number;
  casino_id: string;
  slot_id: string;
}

export const paginationApi = createApi({
  tagTypes: [],
  reducerPath: 'paginationApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    paginationCasinoReviews: build.query<
      PaginationResponse<ICasinoReview>,
      ListRequest
    >({
      query: ({ id, limit, page }) => {
        const params = new URLSearchParams();
        if (limit) {
          params.append('limit', limit.toString());
        }
        if (page) {
          params.append('page', page.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.CASINO_REVIEWS_BY_CASINO}${id}?${params?.toString()}`,
        };
      },
    }),
    paginationCasinos: build.query<
      PaginationResponse<ICasino>,
      CasinoListRequest
    >({
      query: ({ page, limit, sortBy, countries, collections }) => {
        const params = new URLSearchParams();

        if (sortBy) {
          params.set('sortBy', sortBy);
        }

        if (page) {
          params.set('page', page.toString());
        }

        if (limit) {
          params.set('limit', limit.toString());
        }

        if (countries) {
          params.set('countries', countries);
        }

        if (collections) {
          params.set('collections', collections);
        }

        return {
          method: 'GET',
          url: `${URLS.CASINOS}?${params.toString()}`,
        };
      },
    }),
    paginationSlotReviews: build.query<
      PaginationResponse<ISlotReview>,
      SlotReviewsRequest
    >({
      query: ({ casino_id, slot_id, page, limit }) => {
        const params = new URLSearchParams({
          casino_id,
          slot_id,
          isReviewed: 'true',
        });

        if (page) {
          params.append('page', page.toString());
        }
        if (limit) {
          params.append('limit', limit.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.SLOT_REVIEWS_BY_CASINO}/?${params?.toString()}`,
        };
      },
    }),
    paginationSlot: build.query<PaginationResponse<ISlot>, SlotListRequest>({
      query: ({ page, limit, countries, collections }) => {
        const params = new URLSearchParams();

        if (page) {
          params.set('page', page.toString());
        }

        if (limit) {
          params.set('limit', limit.toString());
        }

        if (countries) {
          params.set('countries', countries);
        }

        if (collections) {
          params.set('collections', collections);
        }

        return {
          method: 'GET',
          url: `${URLS.SLOTS}?${params.toString()}`,
        };
      },
    }),
    paginationBigWins: build.query<
      PaginationResponse<IBigWins>,
      BigwinsRequest
    >({
      query: ({ page, limit, slot }) => {
        const params = new URLSearchParams();

        if (page) {
          params.set('page', page.toString());
        }

        if (limit) {
          params.set('limit', limit.toString());
        }

        if (slot) {
          params.set('slot', slot.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.BIGWINS}?${params.toString()}`,
        };
      },
    }),
  }),
});

type InitialState = {
  paginationCasinos: ICasino[];
  paginationCasinosOptions: {
    currentRegion: string | null;
    currentCollection: string | null;
  };

  paginationSlot: ISlot[];
  paginationSlotOptions: { currentCollection: string | null };

  paginationCasinoReviews: ICasinoReview[];
  paginationCasinoReviewsOptions: { currentId: string | null };

  paginationBigwins: IBigWins[];
  paginationBigwinsOptions: { currentId: string | null; page: number };
};

const initialState: InitialState = {
  //casino reviews
  paginationCasinoReviewsOptions: {
    currentId: null,
  },
  paginationCasinoReviews: [],

  // slots
  paginationSlotOptions: {
    currentCollection: null,
  },
  paginationSlot: [],

  //casinos
  paginationCasinosOptions: {
    currentRegion: null,
    currentCollection: null,
  },
  paginationCasinos: [],

  //bigwins
  paginationBigwins: [],
  paginationBigwinsOptions: { currentId: null, page: 0 },
};

export const paginationSlice = createSlice({
  name: 'paginationSlice',
  initialState,
  reducers: {
    //  casino reviews
    clearCasinoReviewsPagination: (state) => ({
      ...state,
      paginationCasinoReviews: [],
    }),
    setPaginationCasinoReviewsCurrentId: (state, { payload }) => ({
      ...state,
      paginationCasinoReviewsOptions: {
        ...state.paginationCasinoReviewsOptions,
        currentId: payload,
      },
    }),

    //slots
    clearSlotsPagination: (state) => ({
      ...state,
      paginationSlot: [],
    }),
    setPaginationSlotCurrentCollection: (state, { payload }) => ({
      ...state,
      paginationSlotOptions: {
        ...state.paginationSlotOptions,
        currentCollection: payload,
      },
    }),

    //casinos
    clearCasinosPagination: (state) => ({
      ...state,
      paginationCasinos: [],
    }),
    setPaginationCasinosCurrentCollection: (state, { payload }) => ({
      ...state,
      paginationCasinosOptions: {
        ...state.paginationCasinosOptions,
        currentCollection: payload,
      },
    }),
    setPaginationCasinosCurrentRegion: (state, { payload }) => ({
      ...state,
      paginationCasinosOptions: {
        ...state.paginationCasinosOptions,
        currentRegion: payload,
      },
    }),

    //bigwins
    clearBigwinsPagination: (state) => ({
      ...state,
      paginationBigwins: [],
      paginationBigwinsOptions: { currentId: null, page: 1 },
    }),
    setPaginationBigwinsOptions: (state, { payload }) => ({
      ...state,
      paginationBigwinsOptions: {
        ...state.paginationCasinosOptions,
        ...payload,
      },
    }),
  },
  extraReducers: (builder) => {
    //  casino reviews
    builder.addMatcher(
      paginationApi.endpoints.paginationCasinoReviews.matchFulfilled,
      (state, { payload }) => {
        if (state.paginationCasinoReviews) {
          return {
            ...state,
            paginationCasinoReviews: [
              ...state.paginationCasinoReviews,
              ...payload.results,
            ],
          };
        }
        return { ...state, search: payload.results };
      }
    );

    //slots
    builder.addMatcher(
      paginationApi.endpoints.paginationSlot.matchFulfilled,
      (state, { payload }) => {
        if (state.paginationSlot) {
          return {
            ...state,
            paginationSlot: [...state.paginationSlot, ...payload.results],
          };
        }
        return { ...state, paginationSlot: payload.results };
      }
    );

    //casinos
    builder.addMatcher(
      paginationApi.endpoints.paginationCasinos.matchFulfilled,
      (state, { payload }) => {
        if (state.paginationCasinos) {
          return {
            ...state,
            paginationCasinos: [...state.paginationCasinos, ...payload.results],
          };
        }
        return { ...state, paginationCasinos: payload.results };
      }
    );

    //bigwins
    builder.addMatcher(
      paginationApi.endpoints.paginationBigWins.matchFulfilled,
      (state, { payload }) => {
        if (state.paginationBigwins) {
          return {
            ...state,
            paginationBigwins: [...state.paginationBigwins, ...payload.results],
          };
        }
        return { ...state, paginationBigwins: payload.results };
      }
    );
  },
});

export const {
  actions: {
    clearSlotsPagination,
    clearCasinosPagination,
    clearBigwinsPagination,
    setPaginationBigwinsOptions,
    clearCasinoReviewsPagination,
    setPaginationCasinosCurrentRegion,
    setPaginationSlotCurrentCollection,
    setPaginationCasinoReviewsCurrentId,
    setPaginationCasinosCurrentCollection,
  },
} = paginationSlice;

const paginationStore = (store: RootState) => store?.paginationSlice;

export const selectPaginationCasinoReviews = () =>
  createSelector(
    [paginationStore],
    (store) => store?.paginationCasinoReviews ?? []
  );
export const selectPaginationCasinoReviewsOptions = () =>
  createSelector(
    [paginationStore],
    (store) => store?.paginationCasinoReviewsOptions ?? { currentId: null }
  );

export const selectSlotsPagination = () =>
  createSelector([paginationStore], (store) => store?.paginationSlot ?? []);
export const selectPaginationSlotOptions = () =>
  createSelector(
    [paginationStore],
    (store) => store?.paginationSlotOptions ?? { currentCollection: null }
  );

export const selectPaginationCasinos = () =>
  createSelector([paginationStore], (store) => store?.paginationCasinos ?? []);
export const selectPaginationCasinosOptions = () =>
  createSelector(
    [paginationStore],
    (store) => store?.paginationCasinosOptions ?? { currentCollection: null }
  );

export const selectPaginationBigwins = () =>
  createSelector([paginationStore], (store) => store?.paginationBigwins ?? []);
export const selectPaginationBigwinsOptions = () =>
  createSelector(
    [paginationStore],
    (store) => store?.paginationBigwinsOptions ?? { currentId: null, page: 1 }
  );

export const {
  useLazyPaginationSlotQuery,
  useLazyPaginationBigWinsQuery,
  useLazyPaginationCasinosQuery,
  useLazyPaginationSlotReviewsQuery,
  useLazyPaginationCasinoReviewsQuery,
} = paginationApi;
