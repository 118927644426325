import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ISlotCollection } from 'constants/types';
import { apiQuery } from 'store/query';
import { PaginationResponse, RequestById } from './types';
// import { PaginationResponse, RequestById } from './types';

export const slotCollectionApi = createApi({
  tagTypes: [],
  reducerPath: 'slotCollectionApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    slotCollections: build.query<PaginationResponse<ISlotCollection>, void>({
      query: () => ({
        method: 'GET',
        url: URLS.SLOT_COLLECTIONS,
      }),
    }),
    slotCollectionByName: build.query<ISlotCollection, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.SLOT_COLLECTION_BY_NAME}/${id}`,
      }),
    }),
  }),
});

export const { useSlotCollectionsQuery, useSlotCollectionByNameQuery } =
  slotCollectionApi;
