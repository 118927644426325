import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { IRegionItem } from 'constants/types';
import { apiQuery } from 'store/query';

type CountriesParams = {
  image: string;
  name: string;
  code: string;
};

export const countriesApi = createApi({
  tagTypes: [],
  reducerPath: 'countriesApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    countries: build.query<IRegionItem[], void>({
      query: () => ({
        url: URLS.COUNTRIES,
        method: 'GET',
      }),
    }),
    сreate_countries: build.mutation<void, any>({
      query: ({ image, name, code }: CountriesParams) => ({
        method: 'POST',
        url: URLS.COUNTRIES,
        data: {
          image,
          name,
          code,
        },
      }),
    }),
    update_countries: build.mutation<void, any>({
      query: ({ image, name, code }: CountriesParams) => ({
        method: 'POST',
        url: URLS.COUNTRIES_UPDATE,
        data: {
          image,
          name,
          code,
        },
      }),
    }),
    remove_countries: build.mutation<void, any>({
      query: ({ image }) => ({
        method: 'DELETE',
        url: URLS.COUNTRIES,
        data: {
          image,
        },
      }),
    }),
  }),
});

export const {
  useCountriesQuery,
  useСreate_countriesMutation,
  useUpdate_countriesMutation,
  useRemove_countriesMutation,
} = countriesApi;
