import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ICasinoReview, ICasinoReviewGraph } from 'constants/types';
import { apiQuery } from 'store/query';
import {
  CreateCasinoReviewsRequest,
  PaginationRequest,
  PaginationResponse,
  RequestById,
  ToogleLikeRequest,
} from './types';
import { UsersTags, usersApi } from './users';

interface ListRequest extends PaginationRequest, RequestById {}

export const casinoReviewsApi = createApi({
  tagTypes: ['CasinoReviews'],
  reducerPath: 'casinoReviewsApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    casinoReviews: build.query<PaginationResponse<ICasinoReview>, ListRequest>({
      query: ({ id, limit, page }) => {
        const params = new URLSearchParams();
        if (limit) {
          params.append('limit', limit.toString());
        }
        if (page) {
          params.append('page', page.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.CASINO_REVIEWS_BY_CASINO}${id}?${params?.toString()}`,
        };
      },
      providesTags: ['CasinoReviews'],
    }),
    casinoReviewsGraph: build.query<
      PaginationResponse<ICasinoReviewGraph>,
      RequestById
    >({
      query: ({ id }) => {
        return {
          method: 'GET',
          url: `${URLS.CASINO_REVIEWS_GRAPG_BY_CASINO}${id}`,
        };
      },
      providesTags: ['CasinoReviews'],
    }),
    casinoReview: build.query<ICasinoReview, RequestById>({
      query: ({ id }) => {
        return {
          method: 'GET',
          url: `${URLS.CASINO_REVIEWS}${id}`,
        };
      },
    }),
    toogleLike: build.mutation<void, ToogleLikeRequest>({
      query: ({ reviewId }) => ({
        method: 'POST',
        url: URLS.CASINO_REVIEWS_LIKES,
        data: {
          review_id: reviewId,
        },
      }),
      invalidatesTags: ['CasinoReviews'],
    }),
    toogleDislike: build.mutation<void, ToogleLikeRequest>({
      query: ({ reviewId }) => ({
        method: 'POST',
        url: URLS.CASINO_REVIEWS_DISLIKES,
        data: {
          review_id: reviewId,
        },
      }),
      invalidatesTags: ['CasinoReviews'],
    }),
    createCasinoReviews: build.mutation<void, CreateCasinoReviewsRequest>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: ({ casinoId, recommended, review }) => ({
        method: 'POST',
        url: URLS.CASINO_REVIEWS,
        data: {
          casino_id: casinoId,
          recommended,
          review,
        },
      }),
      invalidatesTags: ['CasinoReviews'],
    }),
    updateCasinoReviews: build.mutation<void, CreateCasinoReviewsRequest>({
      query: ({ casinoId, recommended, review }) => ({
        method: 'POST',
        url: URLS.CASINO_REVIEWS_UPDATE,
        data: {
          casino_id: casinoId,
          recommended,
          review,
        },
      }),
    }),
    deleteCasinoReviews: build.mutation<void, any>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: URLS.CASINO_REVIEWS,
        data: {
          id,
        },
      }),
    }),
  }),
});

export const {
  useCasinoReviewQuery,
  useCasinoReviewsQuery,
  useToogleLikeMutation,
  useToogleDislikeMutation,
  useCasinoReviewsGraphQuery,
  useUpdateCasinoReviewsMutation,
  useCreateCasinoReviewsMutation,
} = casinoReviewsApi;
