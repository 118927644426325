export const Google = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 10.2252C20.0002 9.56683 19.9406 8.94183 19.8384 8.3335H10.2173V12.0918H15.726C15.4791 13.3252 14.7554 14.3668 13.6826 15.0752V17.5752H16.9691C18.8933 15.8335 20.0002 13.2668 20.0002 10.2252Z"
        fill="#4285F4"
      />
      <path
        d="M10.2174 19.9999C12.976 19.9999 15.2834 19.0999 16.9692 17.5749L13.6827 15.0749C12.7632 15.6749 11.5967 16.0415 10.2174 16.0415C7.55244 16.0415 5.29616 14.2832 4.48731 11.9082H1.09863V14.4832C2.77594 17.7499 6.22422 19.9999 10.2174 19.9999Z"
        fill="#34A853"
      />
      <path
        d="M4.48702 11.9083C4.27416 11.3083 4.16347 10.6667 4.16347 9.99999C4.16347 9.33333 4.28267 8.69166 4.48702 8.09166V5.51666H1.09834C0.400169 6.86666 0 8.38333 0 9.99999C0 11.6167 0.400169 13.1333 1.09834 14.4833L4.48702 11.9083Z"
        fill="#FBBC05"
      />
      <path
        d="M10.2174 3.95833C11.7244 3.95833 13.0697 4.46667 14.134 5.45834L17.0458 2.60833C15.2834 0.991668 12.976 0 10.2174 0C6.22422 0 2.77594 2.25 1.09863 5.51667L4.48731 8.09167C5.29616 5.71667 7.55244 3.95833 10.2174 3.95833Z"
        fill="#EA4335"
      />
    </svg>
  );
};
