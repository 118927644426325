export const ArrowSlider = ({ width = '30', height = '30' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M13.0605 23.5605L21.621 15L13.0605 6.43945L10.9395 8.56045L17.379 15L10.9395 21.4395L13.0605 23.5605Z"
        fill="#121212"
      />
    </svg>
  );
};
