export const Youtube = ({ width = '31', height = '30' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
    >
      <g clipPath="url(#clip0_240_3954)">
        <path
          d="M29.8361 7.41127L29.8736 7.65627C29.5111 6.37002 28.5324 5.37752 27.2886 5.01627L27.2624 5.01002C24.9236 4.37502 15.5124 4.37502 15.5124 4.37502C15.5124 4.37502 6.12488 4.36252 3.76238 5.01002C2.49488 5.37752 1.51488 6.37002 1.15863 7.63002L1.15238 7.65627C0.278629 12.22 0.272379 17.7038 1.19113 22.59L1.15238 22.3425C1.51488 23.6288 2.49363 24.6213 3.73738 24.9825L3.76363 24.9888C6.09988 25.625 15.5136 25.625 15.5136 25.625C15.5136 25.625 24.8999 25.625 27.2636 24.9888C28.5324 24.6213 29.5124 23.6288 29.8686 22.3688L29.8749 22.3425C30.2724 20.22 30.4999 17.7775 30.4999 15.2825C30.4999 15.1913 30.4999 15.0988 30.4986 15.0063C30.4999 14.9213 30.4999 14.82 30.4999 14.7188C30.4999 12.2225 30.2724 9.78002 29.8361 7.41127ZM12.5099 19.5638V10.4475L20.3424 15.0125L12.5099 19.5638Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_3954">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
