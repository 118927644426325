import CryptoJS from 'crypto-js';
import { SECRET_KEY } from 'api/constants';

const themeKey = 'theme';
const tokenKey = 'token';
const currentRegionKey = 'curentRegion';
const regionManuallySelectedKey = 'regionManuallySelected';

// utm_source,
// utm_medium,
// utm_campaign,
// utm_content,
// utm_term,

const utmSourceKey = 'utm_source';
const utmMediumKey = 'utm_medium';
const utmCampaignKey = 'utm_campaign';
const utmContentKey = 'utm_content';
const utmTermKey = 'utm_term';
const referrerKey = 'session_source';

export const clearToken = () => localStorage.removeItem(tokenKey);

export const clearAll = () => {
  clearToken();
};

export const saveToken = (token: string) => {
  const encrypted = CryptoJS.AES.encrypt(token, SECRET_KEY);
  localStorage.setItem(tokenKey, encrypted.toString());
};

export const getToken = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem(tokenKey);
    if (!token) return undefined;
    try {
      return CryptoJS.AES.decrypt(token, SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );
    } catch (err) {
      clearAll();
      return undefined;
    }
  }
};

export const saveDarkTheme = (isDarkTheme?: boolean) => {
  if (typeof isDarkTheme === 'boolean') {
    localStorage.setItem(themeKey, String(isDarkTheme));
  } else {
    localStorage.removeItem(themeKey);
  }
};

export const getIsDarkTheme = (): boolean | undefined => {
  if (typeof window !== 'undefined') {
    const isDarkTheme = localStorage.getItem(themeKey);
    if (isDarkTheme === null) return undefined;
    return isDarkTheme === 'true';
  }
  return false;
};

export const saveCurrentRegion = (currentRegion?: string | null) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(currentRegionKey, String(currentRegion));
  }
};

export const getCurrentRegion = (): string | undefined | null => {
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem(currentRegionKey);
    if (value === 'undefined') {
      return null;
    }
    return value;
  }
  return null;
};

export const setRegionManuallySelected = (value: boolean) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(regionManuallySelectedKey, String(value));
  }
};

export const getIsRegionManuallySelected = (): string | undefined | null => {
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem(regionManuallySelectedKey);
    if (value === 'undefined') {
      return null;
    }
    return value;
  }
  return null;
};

export const saveRefferer = (value: string) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(referrerKey, value);
  }
};

export const clearRefferer = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(referrerKey);
  }
};

export const saveUtm = (utm: Record<string, string>) => {
  if (typeof window !== 'undefined') {
    if (utm.utm_source) {
      localStorage.setItem(utmSourceKey, utm.utm_source);
    }
    if (utm.utm_medium) {
      localStorage.setItem(utmMediumKey, utm.utm_medium);
    }
    if (utm.utm_campaign) {
      localStorage.setItem(utmCampaignKey, utm.utm_campaign);
    }
    if (utm.utm_content) {
      localStorage.setItem(utmContentKey, utm.utm_content);
    }
    if (utm.utm_term) {
      localStorage.setItem(utmTermKey, utm.utm_term);
    }
  }
};

export const clearUtm = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(utmSourceKey);
    localStorage.removeItem(utmMediumKey);
    localStorage.removeItem(utmCampaignKey);
    localStorage.removeItem(utmContentKey);
    localStorage.removeItem(utmTermKey);
  }
};

export const getUtm = (): Record<string, string | null> => {
  if (typeof window !== 'undefined') {
    const utm = {
      utm_source: localStorage.getItem(utmSourceKey),
      utm_medium: localStorage.getItem(utmMediumKey),
      utm_campaign: localStorage.getItem(utmCampaignKey),
      utm_content: localStorage.getItem(utmContentKey),
      utm_term: localStorage.getItem(utmTermKey),
      referrer: localStorage.getItem(referrerKey),
    };
    return utm;
  }
  return {};
};
