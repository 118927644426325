import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ICasino, IProvider, ISlot } from 'constants/types';
import { apiQuery } from 'store/query';

type SearchRequest = {
  searchQuery: string;
};

type SearchResponse = {
  results: {
    slots: ISlot[];
    casinos: ICasino[];
    providers: IProvider[];
  };
};

export const searchApi = createApi({
  tagTypes: [],
  reducerPath: 'searchApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    search: build.query<SearchResponse, SearchRequest>({
      query: ({ searchQuery }) => {
        const params = new URLSearchParams({
          searchQuery,
        });

        return {
          method: 'GET',
          url: `${URLS.SEARCH}/?${params.toString()}`,
        };
      },
    }),
  }),
});

export const { useSearchQuery } = searchApi;
