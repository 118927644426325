export const Flag = ({
  width = '20',
  height = '20',
  fillColor = '#CAF413',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.303 4L9.303 2H4V0H2V20H4V12H8.697L11.697 14H18V4H12.303Z"
        fill={fillColor}
      />
    </svg>
  );
};
