import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { IProvider, ISlotReview } from 'constants/types';
import { apiQuery } from 'store/query';
import { PaginationRequest, PaginationResponse } from './types';

interface SlotReviewsRequest extends PaginationRequest {
  casino_id: string;
  slot_id: string;
}

interface SlotAllReviewsRequest extends PaginationRequest {
  casino_id: string;
  isReviewed?: string;
}

interface SlotAllReviewsBySlot extends PaginationRequest {
  slot_id: string;
}

interface CreateSlotReviewRequest {
  casino_id: string;
  slot_id: string;
  value: number;
  image: File;
}

interface SlotReviewsResponse extends PaginationResponse<ISlotReview> {
  averageRating: number;
}

interface SlotAllReviewsResponse extends PaginationResponse<ISlotReview> {
  provider: IProvider;
}

export const slotReviewsApi = createApi({
  tagTypes: ['SlotReviews'],
  reducerPath: 'slotReviewsApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    slotReviews: build.query<SlotReviewsResponse, SlotReviewsRequest>({
      query: ({ casino_id, slot_id, page, limit }) => {
        const params = new URLSearchParams({
          casino_id,
          slot_id,
          isReviewed: 'true',
        });

        if (page) {
          params.append('page', page.toString());
        }
        if (limit) {
          params.append('limit', limit.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.SLOT_REVIEWS_BY_CASINO}/?${params?.toString()}`,
        };
      },
      providesTags: ['SlotReviews'],
    }),
    slotReviewsByCasino: build.query<
      SlotAllReviewsResponse,
      SlotAllReviewsRequest
    >({
      query: ({ casino_id, isReviewed = 'true' }) => {
        const params = new URLSearchParams({
          casino_id,
          isReviewed,
        });

        return {
          method: 'GET',
          url: `${URLS.SLOT_REVIEWS_ALL_BY_CASINO}/?${params?.toString()}`,
        };
      },
      providesTags: ['SlotReviews'],
    }),
    slotReviewsBySlot: build.query<
      SlotAllReviewsResponse,
      SlotAllReviewsBySlot
    >({
      query: ({ slot_id }) => {
        const params = new URLSearchParams({
          slot_id,
          isReviewed: 'true',
        });

        return {
          method: 'GET',
          url: `${URLS.SLOT_REVIEWS_ALL_BY_SLOT}/?${params?.toString()}`,
        };
      },
      providesTags: ['SlotReviews'],
    }),
    createSlotReview: build.mutation<void, CreateSlotReviewRequest>({
      query: ({ casino_id, slot_id, value, image }) => {
        const formData = new FormData();
        formData.append('casino_id', casino_id);
        formData.append('slot_id', slot_id);
        formData.append('value', value.toString());
        formData.append('image', image);

        return {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          method: 'POST',
          url: URLS.SLOT_REVIEWS,
          data: formData,
        };
      },
      invalidatesTags: ['SlotReviews'],
    }),
  }),
});

export const {
  useSlotReviewsQuery,
  useSlotReviewsBySlotQuery,
  useCreateSlotReviewMutation,
  useSlotReviewsByCasinoQuery,
} = slotReviewsApi;
