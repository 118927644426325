export const Remove = ({ width = '20', height = '20', fill = '#FFF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 20V2C18 0.897 17.103 0 16 0H4C2.897 0 2 0.897 2 2V20L10 15.428L18 20ZM6 9V7H14V9H6Z"
        fill={fill}
      />
    </svg>
  );
};
