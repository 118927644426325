export const Increase = ({ width = 20, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19 13.344L16.879 15.465L13.707 12.293L12.293 13.707L15.465 16.879L13.344 19H19V13.344ZM1 6.656L3.121 4.535L6.293 7.707L7.707 6.293L4.535 3.121L6.656 1H1V6.656ZM19 1H13.344L15.465 3.121L12.293 6.293L13.707 7.707L16.879 4.535L19 6.656V1ZM1 19H6.656L4.535 16.879L7.707 13.707L6.293 12.293L3.121 15.465L1 13.344V19Z"
        fill="white"
      />
    </svg>
  );
};
