export const Casinos = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_68_1495)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20ZM10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2ZM10.2052 5.12976C10.7896 5.80093 12.1991 7.35278 13.7354 8.55804C14.4325 9.10489 14.8404 9.92717 14.8404 10.7984C14.8404 12.3447 13.5521 13.5982 11.9629 13.5982C11.4254 13.5982 10.9365 13.3961 10.5718 13.0659V13.6168C10.5718 14.1158 10.8326 14.2821 11.1167 14.4633C11.2145 14.5257 11.315 14.5898 11.4086 14.6698C11.4966 14.745 11.4419 14.8894 11.3262 14.8894H8.50438C8.38831 14.8894 8.33373 14.744 8.42262 14.6693C8.51516 14.5916 8.6144 14.5282 8.71117 14.4663C9.0009 14.2812 9.26856 14.1101 9.26856 13.6119V13.0659C8.90385 13.3961 8.41495 13.5982 7.87748 13.5982C6.28829 13.5982 5 12.3447 5 10.7984C5 9.92717 5.40793 9.10489 6.10498 8.55804C7.6413 7.35277 9.05074 5.80091 9.63524 5.12976C9.78591 4.95675 10.0545 4.95675 10.2052 5.12976Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_1495">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
