export const DislikeRecommend = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2 19H3V6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8V17C0 17.5304 0.210714 18.0391 0.585786 18.4142C0.960859 18.7893 1.46957 19 2 19ZM18 6H11L12.122 2.632C12.2221 2.33141 12.2494 2.01135 12.2016 1.69815C12.1538 1.38496 12.0322 1.08761 11.847 0.830595C11.6617 0.573576 11.4181 0.364242 11.1361 0.219836C10.8541 0.0754294 10.5418 8.21128e-05 10.225 0H10L5 5.438V19H16L19.912 10.404L20 10V8C20 7.46957 19.7893 6.96086 19.4142 6.58579C19.0391 6.21071 18.5304 6 18 6Z"
        fill="none"
      />
    </svg>
  );
};
