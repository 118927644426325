export const Trophy = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bx-trophy" clipPath="url(#clip0_240_1189)">
        <path
          id="figure"
          d="M15.2 1.6H12.8V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0H4C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V1.6H0.8C0.587827 1.6 0.384344 1.68429 0.234315 1.83431C0.0842854 1.98434 0 2.18783 0 2.4V4.8C0 8.248 1.4392 10.328 3.8552 10.4096C4.20889 11.0174 4.6918 11.5401 5.26978 11.9408C5.84776 12.3414 6.5067 12.6101 7.2 12.728V14.4H5.6V16H10.4V14.4H8.8V12.728C9.49314 12.6097 10.1519 12.3407 10.7298 11.9401C11.3077 11.5396 11.7907 11.0171 12.1448 10.4096C14.5608 10.328 16 8.248 16 4.8V2.4C16 2.18783 15.9157 1.98434 15.7657 1.83431C15.6157 1.68429 15.4122 1.6 15.2 1.6ZM1.6 4.8V3.2H3.2V8.664C1.7728 8.0624 1.6 5.8392 1.6 4.8ZM8 11.2C6.2352 11.2 4.8 9.7648 4.8 8V1.6H11.2V8C11.2 9.7648 9.7648 11.2 8 11.2ZM12.8 8.664V3.2H14.4V4.8C14.4 5.8392 14.2272 8.0624 12.8 8.664Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_1189">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
