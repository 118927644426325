export const Home = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_373_1687)">
        <path
          d="M2.40005 16.0003H13.6C14.0244 16.0003 14.4314 15.8317 14.7314 15.5317C15.0315 15.2316 15.2 14.8247 15.2 14.4003V7.20032C15.2007 7.09503 15.1805 6.99066 15.1407 6.89319C15.1009 6.79572 15.0422 6.70707 14.968 6.63232L8.56805 0.232317C8.41816 0.0833158 8.2154 -0.000317574 8.00405 -0.000317574C7.7927 -0.000317574 7.58994 0.0833158 7.44005 0.232317L1.04005 6.63232C0.964458 6.70641 0.904322 6.79476 0.863125 6.89226C0.821928 6.98976 0.800488 7.09447 0.800049 7.20032V14.4003C0.800049 14.8247 0.96862 15.2316 1.26868 15.5317C1.56874 15.8317 1.9757 16.0003 2.40005 16.0003ZM6.40005 14.4003V10.4003H9.60005V14.4003H6.40005ZM2.40005 7.52832L8.00005 1.92832L13.6 7.52832V14.4003H11.2V10.4003C11.2 9.97597 11.0315 9.569 10.7314 9.26895C10.4314 8.96889 10.0244 8.80032 9.60005 8.80032H6.40005C5.9757 8.80032 5.56874 8.96889 5.26868 9.26895C4.96862 9.569 4.80005 9.97597 4.80005 10.4003V14.4003H2.40005V7.52832Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_373_1687">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
