export { Bad } from 'assets/icons/bad';
export { Menu } from 'assets/icons/menu';
export { Slot } from 'assets/icons/slot';
export { Good } from 'assets/icons/good';
export { Home } from 'assets/icons/home';
export { Save } from 'assets/icons/save';
export { Edit } from 'assets/icons/edit';
export { Like } from 'assets/icons/like';
export { Play } from 'assets/icons/play';
export { Flag } from 'assets/icons/flag';
export { User } from 'assets/icons/user';
export { Star } from 'assets/icons/star';
export { Guide } from 'assets/icons/guide';
export { Close } from 'assets/icons/close';
export { Arrow } from 'assets/icons/arrow';
export { About } from 'assets/icons/about';
export { Smile } from 'assets/icons/smile';
export { Cloud } from 'assets/icons/cloud';
export { Remove } from 'assets/icons/remove';
export { Google } from 'assets/icons/google';
export { Trophy } from 'assets/icons/trophy';
export { Search } from 'assets/icons/search';
export { Cherry } from 'assets/icons/cherry';
export { Logout } from 'assets/icons/logout';
export { Author } from 'assets/icons/author';
export { Casinos } from 'assets/icons/casinos';
export { Youtube } from 'assets/icons/youtube';
export { Twitter } from 'assets/icons/twitter';
export { Inspect } from 'assets/icons/inspect';
export { Dislike } from 'assets/icons/dislike';
export { Comment } from 'assets/icons/comment';
export { SlotBig } from 'assets/icons/slot-big';
export { Facebook } from 'assets/icons/facebook';
export { Increase } from 'assets/icons/increase';
export { Telegram } from 'assets/icons/telegram';
export { LogoPost } from 'assets/icons/post-logo';
export { ReadPost } from 'assets/icons/read-post';
export { ThirdPlay } from 'assets/icons/third-play';
export { ArrowLeft } from 'assets/icons/left-arrow';
export { Netherlands } from 'assets/icons/countries';
export { SecondPlay } from 'assets/icons/second-play';
export { SerachPlus } from 'assets/icons/search-plus';
export { AddComment } from 'assets/icons/add-comment';
export { TradeArrow } from 'assets/icons/trade-arrow';
export { AlertError } from 'assets/icons/alert-error';
export { UserSecond } from 'assets/icons/user-second';
export { ArrowSolid } from 'assets/icons/arrow-solid';
export { Inspections } from 'assets/icons/inspections';
export { Calendar } from 'assets/icons/second-calendar';
export { ArrowSlider } from 'assets/icons/slider-arrow';
export { CasinosReview } from 'assets/icons/casinos-review';
export { LikeRecommend } from 'assets/icons/like-recommend';
export { FacebookSecond } from 'assets/icons/facebook-second';
export { AllRegionsFlag } from 'assets/icons/all-regions-flag';
export { StarHalfFilled } from 'assets/icons/star-half-filled';
export { ExclamationMark } from 'assets/icons/exclamation-mark';
export { DislikeRecommend } from 'assets/icons/dislike-recommend';
