import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';

import { apiInstance } from 'api/instance';

interface Error {
  data?: any;
  status?: number;
  message?: string;
}

const parseAxiosError = (error: any) => {
  const axiosError =
    error?.response?.data?.error?.msg || error?.response?.data?.debug?.message;
  if (axiosError) {
    return axiosError;
  }
  return error.message;
};

const axiosBaseQuery =
  ({
    instance,
  }: {
    instance: AxiosInstance;
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    Error
  > =>
  async ({ url, method, data, headers, ...props }) => {
    try {
      const result = await instance({ url, method, data, headers, ...props });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
          message: parseAxiosError(err),
        },
      };
    }
  };

export const apiQuery = axiosBaseQuery({
  instance: apiInstance,
});
