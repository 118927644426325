export const FacebookSecond = ({ width = 31, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8438 29.8178C25.0145 28.6928 30.5 22.4871 30.5 15C30.5 6.71602 23.784 0 15.5 0C7.21602 0 0.5 6.71602 0.5 15C0.5 22.4871 5.98555 28.6928 13.1562 29.8178V29.8177V19.3359H9.34766V15H13.1562V11.6953C13.1562 7.93594 15.3957 5.85938 18.8217 5.85938C20.4629 5.85938 22.1797 6.15234 22.1797 6.15234V9.84375H20.2883C20.1718 9.84375 20.0604 9.84827 19.9538 9.85702C19.3847 9.90377 18.9534 10.0713 18.634 10.318C18.2951 10.5796 18.082 10.9301 17.9637 11.3197C17.8801 11.595 17.8438 11.8897 17.8438 12.1863C17.8438 12.1863 17.8438 12.1863 17.8438 12.1863V15H22.0039L21.3389 19.3359H17.8438V29.8177V29.8178Z"
        fill="white"
      />
    </svg>
  );
};
