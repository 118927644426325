export const Menu = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2H12V6H8V2ZM14 2H18V6H14V2ZM2 2H6V6H2V2ZM8 8H12V12H8V8ZM14 8H18V12H14V8ZM2 8H6V12H2V8Z"
        fill="white"
      />
      <path d="M12 13.5H8V17.5H12V13.5Z" fill="white" />
      <path d="M18 13.5H14V17.5H18V13.5Z" fill="white" />
      <path d="M6 13.5H2V17.5H6V13.5Z" fill="white" />
    </svg>
  );
};
