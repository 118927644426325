import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';

export const ticketsApi = createApi({
  tagTypes: [],
  reducerPath: 'ticketsApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    createTicket: build.mutation<void, { email: string; text: string }>({
      query: ({ email, text }) => ({
        method: 'POST',
        url: URLS.TICKETS,
        data: { email, text },
      }),
    }),
  }),
});

export const { useCreateTicketMutation } = ticketsApi;
