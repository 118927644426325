export const Smile = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_580_3527)">
        <path
          d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18Z"
          fill="white"
        />
        <path
          d="M12.8294 12.828C12.4639 13.1923 12.0322 13.4835 11.5574 13.686C10.7027 14.0468 9.7492 14.0995 8.85995 13.835C7.9707 13.5705 7.20097 13.0053 6.68241 12.236L5.02441 13.355C5.45685 13.9931 6.00701 14.543 6.64541 14.975C7.29507 15.4143 8.025 15.7212 8.79341 15.878C9.59021 16.0405 10.4116 16.0405 11.2084 15.878C11.9768 15.7209 12.7066 15.4141 13.3564 14.975C13.6694 14.763 13.9684 14.517 14.2424 14.244C14.5144 13.973 14.7624 13.673 14.9764 13.355L13.3184 12.236C13.1747 12.4485 13.0109 12.6467 12.8294 12.828Z"
          fill="white"
        />
        <path
          d="M6.5 10C7.32843 10 8 9.32843 8 8.5C8 7.67157 7.32843 7 6.5 7C5.67157 7 5 7.67157 5 8.5C5 9.32843 5.67157 10 6.5 10Z"
          fill="white"
        />
        <path
          d="M13.493 9.986C14.3176 9.986 14.986 9.31756 14.986 8.493C14.986 7.66844 14.3176 7 13.493 7C12.6684 7 12 7.66844 12 8.493C12 9.31756 12.6684 9.986 13.493 9.986Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_580_3527">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
