import { useSelector } from 'react-redux';

import { selectIsLoading } from 'store/slices/loader';

export const LoaderRedux = ({ children }: any) => {
  const isLoading = useSelector(selectIsLoading);
  return (
    <div>
      {
        <>
          {isLoading && <h1>Loading...</h1>}
          {children}
        </>
      }
    </div>
  );
};
