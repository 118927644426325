import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'store/query';

type SendMessageParams = {
  token: string;
  chatId: string;
  text: string;
};

export const exampleApi = createApi({
  reducerPath: 'exampleApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    sendMessage: build.mutation<void, any>({
      query: ({ token, chatId, text }: SendMessageParams) => ({
        url: `${token}/sendMessage?chat_id=${chatId}&text=${text}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useSendMessageMutation } = exampleApi;
