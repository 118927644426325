export const Good = ({ width = '20', height = '20' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          id="figure"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7183 1.64129C17.5839 1.34602 17.2348 1.21383 16.9424 1.35059L13.5624 2.8902C12.7188 3.25176 11.9075 3.88273 11.1801 4.66137C10.8716 2.04512 8.66631 0.00164795 5.96854 0.00164795H4.79663C4.47276 0.00164795 4.21069 0.263718 4.21069 0.587584V1.7595C4.21069 4.66746 6.57624 7.03304 9.48424 7.03304H9.49439C9.31213 7.3748 9.14135 7.7214 8.99701 8.07472C9.00975 8.07348 9.02263 8.07245 9.0355 8.07143C9.07537 8.06826 9.11509 8.0651 9.15006 8.05562C9.54924 7.94855 9.95006 7.8962 10.3474 7.87636C11.2035 6.14261 12.5911 4.58125 14.0357 3.96199L17.4277 2.41723C17.7223 2.28328 17.8522 1.93539 17.7183 1.64129ZM9.45438 9.18745C10.9009 8.79944 12.2989 9.1394 13.1944 10.099C14.2026 11.1799 14.7576 12.6271 14.7577 14.1035C14.7577 15.8773 13.9669 17.5758 12.5873 18.695C11.5349 19.5488 10.2332 19.9997 8.88844 19.9997C5.92692 19.9997 3.61958 17.8656 3.11806 15.3322C2.74556 13.4559 3.264 11.5078 4.53942 10.1236C5.44013 9.14628 6.88508 8.7978 8.34368 9.18803C8.6945 9.28186 9.09676 9.28358 9.45438 9.18745Z"
          fill="#FFAB09"
        />
      </g>
    </svg>
  );
};
