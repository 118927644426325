export const Dislike = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_382_4471)">
        <path
          d="M14.4 0.800049H13.6V11.2H14.4C14.8243 11.2 15.2313 11.0315 15.5314 10.7314C15.8314 10.4314 16 10.0244 16 9.60005V2.40005C16 1.9757 15.8314 1.56874 15.5314 1.26868C15.2313 0.96862 14.8243 0.800049 14.4 0.800049ZM1.6 11.2H7.2L6.3024 13.8944C6.22231 14.1349 6.20049 14.391 6.23874 14.6415C6.27699 14.8921 6.37422 15.13 6.52242 15.3356C6.67061 15.5412 6.86554 15.7087 7.09113 15.8242C7.31673 15.9397 7.56654 16 7.82 16H8L12 11.6496V0.800049H3.2L0.0504 7.71925L0 7.95365V9.60005C0 10.0244 0.168571 10.4314 0.468629 10.7314C0.768687 11.0315 1.17565 11.2 1.6 11.2Z"
          fill="#CAF413"
        />
      </g>
      <defs>
        <clipPath id="clip0_382_4471">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
