const key = 'redirectAfterLogin';

export const saveRedirectOptions = ({
  url,
  action,
}: {
  url: string;
  action: string;
}) => {
  localStorage.setItem(key, JSON.stringify({ url, action }));
};

export const clearRedirectOptions = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

export const getRedirectOptions = () => {
  if (typeof window !== 'undefined') {
    const options = localStorage.getItem(key);
    if (options) {
      return JSON.parse(options);
    }
    return null;
  }
};
