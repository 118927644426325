import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { IWeights } from 'constants/types';
import { apiQuery } from 'store/query';
import { RequestByName } from './types';

export const weightsApi = createApi({
  tagTypes: [],
  reducerPath: 'weightsApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    weightByName: build.query<IWeights, RequestByName>({
      query: ({ name }) => ({
        method: 'GET',
        url: `${URLS.WEIGHTS_BY_NAME}${name}`,
      }),
    }),
  }),
});

export const { useWeightByNameQuery } = weightsApi;
