import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';

import { PaginationResponse, RequestById, RequestByType } from './types';
import { IBlogPost } from 'constants/types';

type PostParams = {
  image: string;
  title: string;
  content: string;
};

export const blogPostApi = createApi({
  tagTypes: [],
  reducerPath: 'blogPostApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    posts: build.query<PaginationResponse<IBlogPost>, Partial<RequestByType>>({
      query: ({ type }) => {
        const params = new URLSearchParams();

        if (type) {
          params.append('type', type);
        }

        return {
          method: 'GET',
          url: `${URLS.BLOG_POSTS}?${params?.toString()}`,
        };
      },
    }),
    createPost: build.mutation<void, any>({
      query: ({ image, title, content }: PostParams) => ({
        method: 'POST',
        url: URLS.BLOG_POSTS,
        data: {
          image,
          title,
          content,
        },
      }),
    }),
    postById: build.query<IBlogPost, RequestById>({
      query: ({ id }) => ({
        url: `${URLS.BLOG_POSTS}/${id}`,
        method: 'GET',
      }),
    }),
    updatePost: build.mutation<void, any>({
      query: ({ image, title, content }: PostParams) => ({
        method: 'POST',
        url: URLS.BLOG_UPDATE_POST,
        data: {
          image,
          title,
          content,
        },
      }),
    }),
    deletePost: build.mutation<void, any>({
      query: ({ id }) => {
        const params = new URLSearchParams({
          post_id: id,
        });
        return {
          method: 'DELETE',
          url: `${URLS.BLOG_POSTS}?${params.toString()}`,
        };
      },
    }),
  }),
});

export const {
  usePostsQuery,
  usePostByIdQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = blogPostApi;
