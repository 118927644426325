import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { ICasino } from 'constants/types';
import { apiQuery } from 'store/query';
import { RequestById, PaginationResponse, PaginationRequest } from './types';

type CreateCasinoParams = {
  image: string;
  name: string;
  description: string;
  rating: string;
  link_play?: string;
  video_slots_review?: string;
  website?: string;
  countries_ids?: string[];
  established?: string;
  software?: string;
  os?: string[];
  payments?: string[];
  languages?: string[];
  support?: string;
  currencies?: string[];
};

interface CasinoBySlotRequest extends RequestById {
  countries?: string | null;
}
interface CasinoRequest extends PaginationRequest {
  ids?: string[];
  collections?: string;
  countries?: string | null;
}

export const casinoApi = createApi({
  tagTypes: [],
  reducerPath: 'casinoApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    casinos: build.query<PaginationResponse<ICasino>, CasinoRequest>({
      query: ({ ids, page, limit, sortBy, countries, collections }) => {
        const params = new URLSearchParams();

        if (sortBy) {
          params.set('sortBy', sortBy);
        }

        if (page) {
          params.set('page', page.toString());
        }

        if (limit) {
          params.set('limit', limit.toString());
        }

        if (countries) {
          params.set('countries', countries);
        }

        if (collections) {
          params.set('collections', collections);
        }

        return {
          method: 'GET',
          url: ids
            ? `${URLS.CASINOS}${ids}`
            : `${URLS.CASINOS}?${params.toString()}`,
        };
      },
    }),

    casinosBySlot: build.query<
      PaginationResponse<ICasino>,
      CasinoBySlotRequest
    >({
      query: ({ id, countries }) => {
        const params = new URLSearchParams();

        if (countries) {
          params.set('countries', countries);
        }

        return {
          method: 'GET',
          url: `${URLS.CASINOS_BY_SLOT}${id}?${params?.toString()}`,
        };
      },
    }),

    casinoById: build.query<ICasino & PaginationResponse<ICasino>, RequestById>(
      {
        query: ({ id }) => ({
          url: `${URLS.CASINOS}${id}`,
          method: 'GET',
        }),
      }
    ),
    createCasino: build.mutation<void, any>({
      query: ({ image, name, description, rating }: CreateCasinoParams) => ({
        url: URLS.CASINOS,
        method: 'POST',
        data: {
          image,
          name,
          description,
          rating,
        },
      }),
    }),
    updateCasino: build.mutation<void, any>({
      query: ({ image, name, description, rating }: CreateCasinoParams) => ({
        url: URLS.UPDATE_CASINOS,
        method: 'POST',
        data: {
          image,
          name,
          description,
          rating,
        },
      }),
    }),
    deleteCasino: build.mutation<void, any>({
      query: ({ id }) => {
        const params = new URLSearchParams({
          casino_id: id,
        });
        return {
          method: 'DELETE',
          url: `${URLS.CASINOS} + ${params.toString()}`,
        };
      },
    }),
  }),
});

export const {
  useCasinosQuery,
  useCasinoByIdQuery,
  useCasinosBySlotQuery,
  useCreateCasinoMutation,
  useUpdateCasinoMutation,
  useDeleteCasinoMutation,
} = casinoApi;
