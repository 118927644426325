export const Bad = ({ width = '20', height = '20' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="Group 13">
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2962 1.23733C13.4859 0.976095 13.8553 0.922785 14.1159 1.11606C14.3754 1.30857 14.4302 1.67585 14.2372 1.9357L12.0171 4.92954C11.8257 5.17584 11.6543 5.45352 11.5032 5.75551C11.5463 5.74647 11.589 5.73629 11.6312 5.72495C13.3415 5.2662 14.9943 5.66815 16.0531 6.80267C17.2451 8.08068 17.9014 9.79177 17.9014 11.5373C17.9014 13.6345 16.9664 15.6427 15.3353 16.966C14.091 17.9754 12.5519 18.5085 10.962 18.5085C7.46057 18.5085 4.73255 15.9853 4.13958 12.99C4.01384 12.3566 3.97396 11.7163 4.01627 11.0866C4.31942 11.3456 4.4741 11.6469 4.54532 11.9904L4.54679 11.9974L4.5513 12.0183C4.59015 12.1938 4.84742 13.2409 5.7334 14.2261C6.50356 15.0825 7.84625 15.9999 9.95937 16C9.97502 16 9.98952 15.9998 9.99636 15.9996H9.99638L10.0056 15.9995L11 15.9939V11.7648L10.0417 11.7248C9.44991 11.7001 9.12552 11.5242 9.0192 11.4059C8.94279 11.321 8.88744 11.2278 8.85092 11.1516C8.8342 11.1167 8.82319 11.0891 8.81706 11.0726C8.42266 9.27173 7.29815 7.89759 5.64347 7.03127C5.70091 6.96373 5.75979 6.89722 5.8201 6.83176C6.87098 5.69151 8.54848 5.27526 10.2497 5.70785C10.4744 5.15349 10.7516 4.6488 11.0841 4.22083L13.2962 1.23733Z"
            fill="#F9530B"
          />
          <path
            id="Subtract_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.28645 7.52702C3.82925 7.36245 2.81144 7.09759 2.42122 7.02259C1.68824 6.88185 1.00337 7.41642 1.00001 8.13215C0.99748 8.67604 1.39744 9.14562 1.95464 9.25189C2.25067 9.30837 3.16456 9.54698 3.50604 9.67424C4.65844 10.1037 5.3187 10.7949 5.52449 11.7874L5.52766 11.8021C5.5487 11.8972 5.75442 12.754 6.47695 13.5574C7.0884 14.2373 8.17302 14.9999 9.95937 15C9.96675 15 9.97421 14.9999 9.98169 14.9997C9.98781 14.9996 9.99393 14.9995 10 14.9995V12.724C9.28376 12.6941 8.63987 12.4797 8.27568 12.0747C7.96525 11.7295 7.85721 11.3585 7.84989 11.3317C7.4751 9.54683 6.24319 8.23142 4.28645 7.52702ZM2.61568 8.51127C2.89183 8.51127 3.11568 8.28741 3.11568 8.01127C3.11568 7.73512 2.89183 7.51127 2.61568 7.51127C2.33954 7.51127 2.11568 7.73512 2.11568 8.01127C2.11568 8.28741 2.33954 8.51127 2.61568 8.51127Z"
            fill="#F9530B"
          />
        </g>
      </g>
    </svg>
  );
};
