export const SlotBig = ({ width = '20', height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_68_1479)">
        <path
          d="M19 2H16V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H5C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3V6C0 10.31 1.799 12.91 4.819 13.012C5.26111 13.7718 5.86475 14.4252 6.58722 14.926C7.3097 15.4267 8.13337 15.7627 9 15.91V18H7V20H13V18H11V15.91C11.8664 15.7621 12.6899 15.4259 13.4122 14.9252C14.1346 14.4245 14.7384 13.7714 15.181 13.012C18.201 12.91 20 10.31 20 6V3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2ZM2 6V4H4V10.83C2.216 10.078 2 7.299 2 6ZM10 14C7.794 14 6 12.206 6 10V2H14V10C14 12.206 12.206 14 10 14ZM16 10.83V4H18V6C18 7.299 17.784 10.078 16 10.83Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_1479">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
