export const ThirdPlay = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        opacity="0.6"
        d="M29.9925 14.5111C29.7408 6.6303 23.3705 0.260182 15.4899 0.00851118C11.2018 -0.127538 7.19007 1.50464 4.22519 4.56575C1.37489 7.50851 -0.122709 11.388 0.00828665 15.4902C0.259747 23.3707 6.63007 29.741 14.5106 29.9925C14.6747 29.9978 14.8373 30.0003 15.0003 30.0003C19.0974 30.0003 22.924 28.3791 25.7754 25.4353C28.6257 22.4927 30.1235 18.613 29.9925 14.5111Z"
        fill="white"
      />
      <path
        d="M29.9925 14.5111C29.7408 6.6303 23.3705 0.260182 15.4899 0.00851118C11.2018 -0.127538 7.19007 1.50464 4.22519 4.56575C1.37489 7.50851 -0.122709 11.388 0.00828665 15.4902C0.259747 23.3707 6.63007 29.741 14.5106 29.9925C14.6747 29.9978 14.8373 30.0003 15.0003 30.0003C19.0974 30.0003 22.924 28.3791 25.7754 25.4353C28.6257 22.4927 30.1235 18.613 29.9925 14.5111ZM23.5063 23.2376C21.2554 25.5616 18.2345 26.8415 15.0003 26.8415C14.871 26.8415 14.7417 26.8394 14.6113 26.8354C8.39177 26.6368 3.36425 21.6092 3.16544 15.3895C3.06203 12.15 4.24415 9.08656 6.49423 6.76361C8.74516 4.4396 11.7661 3.15977 15.0003 3.15977C15.1296 3.15977 15.2589 3.16187 15.3893 3.16587C21.6088 3.36447 26.6363 8.39199 26.8351 14.6117C26.9385 17.8512 25.7562 20.9147 23.5063 23.2376Z"
        fill="#121212"
      />
      <path
        d="M19.9199 14.1285L13.2682 9.35923C12.5587 8.85041 11.5709 9.35755 11.5709 10.2307V19.7696C11.5709 20.6427 12.5587 21.1498 13.2682 20.641L19.9197 15.8715C20.5165 15.4438 20.5165 14.5565 19.9199 14.1285Z"
        fill="#121212"
      />
    </svg>
  );
};
