import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';

import { IBigWins } from 'constants/types';
import {
  IBigWinsResponse,
  PaginationRequest,
  PaginationResponse,
  RequestById,
} from './types';

type CreateBigWinsParams = {
  image: string;
  name: string;
  description: string;
  slotId: string;
  casinoId: string;
};

export const bigwinsApi = createApi({
  tagTypes: [],
  reducerPath: 'bigwinsApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    bigwins: build.query<PaginationResponse<IBigWins>, PaginationRequest>({
      query: ({ limit, page }) => {
        const params = new URLSearchParams();
        if (limit) {
          params.append('limit', limit.toString());
        }
        if (page) {
          params.append('page', page.toString());
        }

        return {
          method: 'GET',
          url: `${URLS.BIGWINS}?${params.toString()}`,
        };
      },
    }),
    bigwinsByIdCasino: build.query<IBigWinsResponse, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.BIGWINS_BY_CASINO}${id}`,
      }),
    }),
    bigwinsByIdSlot: build.query<IBigWinsResponse, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.BIGWINS_BY_SLOT}${id}`,
      }),
    }),
    bigwinsById: build.query<IBigWins, RequestById>({
      query: ({ id }) => ({
        method: 'GET',
        url: `${URLS.BIGWINS_BY_ID}${id}`,
      }),
    }),
    createBigWins: build.mutation<void, any>({
      query: ({
        image,
        name,
        description,
        slotId,
        casinoId,
      }: CreateBigWinsParams) => ({
        url: URLS.BIGWINS,
        method: 'POST',
        data: {
          image,
          name,
          description,
          slot_id: slotId,
          casino_id: casinoId,
        },
      }),
    }),
    updateBigwins: build.mutation<void, any>({
      query: ({
        image,
        name,
        description,
        slotId,
        casinoId,
      }: CreateBigWinsParams) => ({
        url: URLS.BIGWINS_UPDATE,
        method: 'POST',
        data: {
          image,
          name,
          description,
          slot_id: slotId,
          casino_id: casinoId,
        },
      }),
    }),
    deleteBigWins: build.mutation<void, any>({
      query: ({ id }) => {
        const params = new URLSearchParams({
          casino_id: id,
        });
        return {
          method: 'DELETE',
          url: `${URLS.BIGWINS}/${params.toString()}`,
        };
      },
    }),
  }),
});

export const {
  useBigwinsQuery,
  useBigwinsByIdQuery,
  useBigwinsByIdSlotQuery,
  useDeleteBigWinsMutation,
  useUpdateBigwinsMutation,
  useCreateBigWinsMutation,
  useBigwinsByIdCasinoQuery,
} = bigwinsApi;
