import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';
import { UsersTags, usersApi } from './users';
import { getUtm } from 'utils/storage';

type MagicLinkParams = {
  email: string;
};

type GoogleAuthParams = {
  oauthToken: string;
};

type FacebookAuthParams = {
  accessToken: string;
};

type LoginParams = {
  username: string;
  password: string;
};

type CreateUserParams = {
  email: string;
  username: string;
  password: string;
  type: string;
};

const appendUTMToParams = (params: URLSearchParams) => {
  const utm = getUtm();
  if (utm) {
    Object.entries(utm).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });
  }
};

export const authApi = createApi({
  tagTypes: [],
  reducerPath: 'authApi',
  baseQuery: apiQuery,
  endpoints: (build) => ({
    login: build.mutation<void, any>({
      query: ({ username, password }: LoginParams) => ({
        method: 'POST',
        url: URLS.LOGIN,
        data: { username, password },
      }),
      invalidatesTags: [],
    }),
    magicLink: build.mutation<void, any>({
      query: ({ email }: MagicLinkParams) => ({
        method: 'POST',
        url: URLS.MAGIC_LINK,
        data: { email, ...getUtm() },
      }),
      invalidatesTags: [],
    }),
    googleAuth: build.mutation<{ authToken: string }, GoogleAuthParams>({
      query: ({ oauthToken }) => {
        const params = new URLSearchParams({ oauthToken });
        appendUTMToParams(params);

        return {
          method: 'GET',
          url: `${URLS.GOOGLE_AUTH}?${params.toString()}`,
        };
      },
      invalidatesTags: [],
    }),
    googleLink: build.mutation<void, GoogleAuthParams>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: ({ oauthToken }) => {
        const params = new URLSearchParams({ oauthToken });
        return {
          method: 'GET',
          url: `${URLS.GOOGLE_LINK}?${params.toString()}`,
        };
      },
      invalidatesTags: [],
    }),
    googleUnlink: build.mutation<void, void>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: () => {
        return {
          method: 'GET',
          url: `${URLS.GOOGLE_UNLINK}`,
        };
      },
      invalidatesTags: [],
    }),
    facebookAuth: build.mutation<{ authToken: string }, FacebookAuthParams>({
      query: ({ accessToken }) => {
        const params = new URLSearchParams({ accessToken });
        appendUTMToParams(params);

        return {
          method: 'GET',
          url: `${URLS.FACEBOOK_AUTH}?${params.toString()}`,
        };
      },
      invalidatesTags: [],
    }),
    facebookLink: build.mutation<void, FacebookAuthParams>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: ({ accessToken }) => {
        const params = new URLSearchParams({ accessToken });
        return {
          method: 'GET',
          url: `${URLS.FACEBOOK_LINK}?${params.toString()}`,
        };
      },
      invalidatesTags: [],
    }),
    facebookUnlink: build.mutation<void, void>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(usersApi.util.invalidateTags([UsersTags.ME]));
      },
      query: () => {
        return {
          method: 'GET',
          url: `${URLS.FACEBOOK_UNLINK}`,
        };
      },
      invalidatesTags: [],
    }),
    createUser: build.mutation<void, any>({
      query: ({ email, username, password, type }: CreateUserParams) => ({
        method: 'POST',
        url: URLS.CREATE_USER,
        data: {
          email,
          username,
          password,
          type,
        },
      }),
    }),
  }),
});

export const {
  useMagicLinkMutation,
  useGoogleLinkMutation,
  useGoogleAuthMutation,
  useCreateUserMutation,
  useGoogleUnlinkMutation,
  useFacebookLinkMutation,
  useFacebookAuthMutation,
  useFacebookUnlinkMutation,
} = authApi;
