export const TradeArrow = ({
  width = '20',
  height = '20',
  fill = '#CAF413',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.99997 8.414L12 12.414L17.707 6.707L20 9V3H14L16.293 5.293L12 9.586L7.99997 5.586L0.292969 13.293L1.70697 14.707L7.99997 8.414Z"
        fill={fill}
      />
    </svg>
  );
};
